import { ExportIcon, Delete } from "components/atoms/Icon";
import { InlineBoldParagraph, Paragraph } from "components/atoms/Typography";
import CsvDownloadButton from "react-json-to-csv";
import DangerousModal from "components/molecules/DangerousModal";
import { useState } from "react";
import { useUpdateCustomPlanMeasures } from "mutations/CustomPlans/useUpdateCustomPlanMeasures";
import useCustomPlanSidebarState from "recoilStore/CustomPlan/useCustomPlanSidebarState";
import { useBuildingModel } from "hooks/Building/useBuildingModel";
import { useParams } from "react-router-dom";
import CustomPlanEditButton from "./CustomPlanEditButton";
import styles from "./CustomPlanHeader.module.scss";

interface CustomPlanHeaderProps {
    formattedData: FormattedCarbonReductionData[];
    exportColumnsNames: string[];
    selectedCustomPlan: CustomPlanDataSource[];
    setSelectedRows: (selectedRows: CustomPlanDataSource[]) => void;
    currentCarbonReductionPlanUid: string;
}

function CustomPlanHeader({
    formattedData,
    exportColumnsNames,
    selectedCustomPlan,
    setSelectedRows,
    currentCarbonReductionPlanUid,
}: CustomPlanHeaderProps) {
    const [deleteCRMModalIsOpen, setDeleteCRMModalIsOpen] = useState(false);

    const { mutate, isLoading } = useUpdateCustomPlanMeasures();
    const { state: customPlanSidebarState } = useCustomPlanSidebarState();
    const { buildingModelUid } = useParams();

    if (!buildingModelUid)
        throw new Error("BuildingModelUid param needs to be defined"); // TODO: create a custom hook for this

    const { data: buildingModel } = useBuildingModel(buildingModelUid);

    const handleDeleteCRM = () => {
        const selectedCarbonReductionMeasureTypes =
            customPlanSidebarState.selectedRows.map(
                (selectedRow) => selectedRow.carbonReductionMeasureType
            );

        const targetPlanIndex =
            buildingModel?.buildingModel.carbonReductionPlans.findIndex(
                (plan) => plan?.id === currentCarbonReductionPlanUid
            );

        if (targetPlanIndex === undefined || targetPlanIndex === -1) {
            console.error(
                "No matching carbon reduction plan found for the selected row"
            );
            return;
        }

        const planToModify =
            buildingModel?.buildingModel.carbonReductionPlans[targetPlanIndex];

        if (!planToModify) {
            console.error("Plan to modify not found");
            return;
        }

        const validCarbonReductionMeasureTypes =
            selectedCarbonReductionMeasureTypes.filter(
                (type): type is string => type !== undefined
            );

        const updatedMeasures =
            planToModify.plan?.carbonReductionMeasures?.filter(
                (measure) =>
                    !validCarbonReductionMeasureTypes.includes(
                        measure?.carbonReductionMeasureType || ""
                    )
            );

        const updatedPlanTypedForMutation = updatedMeasures
            ?.map(
                (crm): CarbonReductionMeasureInput => ({
                    year: crm?.yearApplied || 1900,
                    crmName: crm?.carbonReductionMeasureType || "",
                })
            )
            .filter(
                (crm) =>
                    crm.crmName !== "No Action" &&
                    crm.crmName !== "Base building state"
            );

        console.log(updatedPlanTypedForMutation);

        mutate(
            {
                customPlanId: currentCarbonReductionPlanUid,
                buildingModelUid,
                measures: updatedPlanTypedForMutation || [],
            },
            {
                onSuccess: () => {
                    setSelectedRows([]);
                    setDeleteCRMModalIsOpen(false);
                },
            }
        );
    };

    const modalOptions = buildDeleteCustomPlansModalOptions(
        selectedCustomPlan.length,
        isLoading
    );

    return (
        <div className={styles.customPlanHeader}>
            {selectedCustomPlan.length > 0 && (
                // Edit button
                <>
                    <CustomPlanEditButton
                        selectedCustomPlanLength={selectedCustomPlan.length}
                    />

                    {/* // Delete button */}
                    <Paragraph
                        style={{ color: "var(--audette-content-negative)" }}
                    >
                        <button
                            className={styles.deleteCustomPlanButton}
                            type="button"
                            onClick={() => setDeleteCRMModalIsOpen(true)}
                        >
                            <Delete />
                            Delete project
                            {selectedCustomPlan.length === 1 ? "" : "s"} (
                            {selectedCustomPlan.length})
                        </button>
                    </Paragraph>
                </>
            )}

            <DangerousModal
                open={deleteCRMModalIsOpen}
                onCancel={() => setDeleteCRMModalIsOpen(false)}
                onDelete={handleDeleteCRM}
                options={modalOptions}
                styleType="primary-negative"
                loading={isLoading}
            />
            <CsvDownloadButton
                data={formattedData}
                filename="buildingProjectsData.csv"
                headers={exportColumnsNames}
            >
                <div className="measures-page-flex-container">
                    <ExportIcon />
                    <Paragraph>Export</Paragraph>
                </div>
            </CsvDownloadButton>
        </div>
    );
}

const buildDeleteCustomPlansModalOptions = (
    numberOfSelectedIds: number,
    loading: boolean
) => ({
    title: "Remove Project from Plan",
    body: (
        <>
            This will{" "}
            <InlineBoldParagraph>
                remove{" "}
                {numberOfSelectedIds === 1 ? "this project" : "these projects"}{" "}
                ({numberOfSelectedIds}) and all associated data
            </InlineBoldParagraph>
            . Do you wish to proceed?
        </>
    ),
    deleteButtonText: loading
        ? "Loading..."
        : `Remove project${numberOfSelectedIds > 1 ? "s" : ""}`,
});

export default CustomPlanHeader;
