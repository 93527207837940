import { useImpactCards } from "queries/BuildingReport/Project/useImpactCards";
import { CardErrorState } from "components/molecules/ErrorStates";
import { displayValueWithUnit, getDisplayInfo } from "utils/formatting";
import { Header, Paragraph } from "components/atoms/Typography";
import { ImpactCardLoading } from "../ProjectsReport.helper";

interface FinancialImpactProps {
    carbonReductionMeasureType: string;
}

interface ImpactCardsProps extends FinancialImpactProps {
    selected: "total" | "incremental";
}

function ImpactCards({
    carbonReductionMeasureType,
    selected,
}: ImpactCardsProps) {
    const {
        data: impactCardsData,
        isLoading: impactCardsDataIsLoading,
        isError,
    } = useImpactCards(carbonReductionMeasureType);

    if (isError)
        return (
            <div className="small-cards">
                {impactCardsKeys.map((key) => (
                    <CardErrorState key={key} />
                ))}
            </div>
        );
    if (impactCardsDataIsLoading || !impactCardsData)
        return <ImpactCardLoading />;

    return (
        <div className="small-cards">
            {impactCardsKeys.map((key) => {
                const { humanReadable, formatFunction, unit } = getDisplayInfo(
                    key,
                    impactCardsData![selected][key]
                );

                return (
                    <div className="card card--small" key={key}>
                        <Paragraph>
                            {selected === "incremental"
                                ? getIncrementalImpactCardTitle(humanReadable)
                                : humanReadable}
                        </Paragraph>
                        <Header size="medium">
                            {displayValueWithUnit(
                                impactCardsData![selected][key],
                                unit,
                                (v) => formatFunction(v)
                            )}
                        </Header>
                    </div>
                );
            })}
        </div>
    );
}

const getIncrementalImpactCardTitle = (title: string) => {
    if (title.length > 3) return `Incremental ${title.toLowerCase()}`;
    return `Incremental ${title}`; // IRR, ROI
};

const impactCardsKeys: (keyof ProjectReportImpactCardsData)[] = [
    "netPresentValue",
    "internalRateOfReturn",
    "returnOnInvestment",
    "simplePayback",
    "marginalAbatementCost",
];

export default ImpactCards;