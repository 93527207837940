import { Header } from "components/atoms/Typography";
import { useState } from "react";
import classNames from "classnames";
import { Button } from "components/atoms/Button";
import ImpactCards from "./ImpactCards";
import TotalCostAndSavingsCard from "./TotalCostAndSavingsCard";
import CashFlowChartWrapper from "./CashFlowChartWrapper";

interface FinancialImpactProps {
    carbonReductionMeasureType: string;
}

export function FinancialImpact({ carbonReductionMeasureType }: FinancialImpactProps) {
    const [selectedView, setSelectedView] = useState<"total" | "incremental">(
        "total"
    );

    return (
        <div className="financial-impact project-report__section">
            <div className="financial-impact__header">
                <Header size="medium">Financial impact</Header>
                <SelectedViewTab
                    selected={selectedView === "incremental"}
                    title="Incremental"
                    onSelect={() => setSelectedView("incremental")}
                />
                <SelectedViewTab
                    selected={selectedView === "total"}
                    title="Total"
                    onSelect={() => setSelectedView("total")}
                />
            </div>
            <ImpactCards
                carbonReductionMeasureType={carbonReductionMeasureType}
                selected={selectedView}
            />
            <div>
                <TotalCostAndSavingsCard
                    selectedView={selectedView}
                    carbonReductionMeasureType={carbonReductionMeasureType}
                />
                <CashFlowChartWrapper
                    carbonReductionMeasureType={carbonReductionMeasureType}
                    selected={selectedView}
                />
            </div>
        </div>
    );
}

interface SelectedViewTabProps {
    selected: boolean;
    title: string;
    onSelect: () => void;
}

function SelectedViewTab({ selected, title, onSelect }: SelectedViewTabProps) {
    return (
        <Button
            className={classNames("select-view-tab", selected && "selected")}
            onClick={onSelect}
        >
            {title}
        </Button>
    );
}