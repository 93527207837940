import { graphql } from "gql";
import request from "graphql-request";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const mutationUpdateCustomPlanName = (
    accessToken: string,
    data: {
        carbonReductionPlanId: string,
        name: string
    }
) => 
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: mutationQuery,
        variables: {
            id: data.carbonReductionPlanId,
            name: data.name,
        },
        requestHeaders: buildRequestHeaders(accessToken),   
    }).then((data) => {
        if (data) {
            return data;
        }
        throw new Error("Error updating custom plan name");
    })
    .catch((error) => {
        console.error("GraphQL Error:", error);
        throw error; 
    });

const mutationQuery = graphql(`
    mutation updateCustomPlanName ($id: Uuid!, $name: String!) {
        updateCustomPlanName (id: $id, name: $name)
    }
`);        

export default mutationUpdateCustomPlanName;