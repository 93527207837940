import { Button } from "components/atoms/Button";
import { Header } from "components/atoms/Typography";
import Sidebar from "components/molecules/Sidebar";
import { useUpdateCustomPlanName } from "mutations/CustomPlans/useUpdateCustomPlanName";
import { useEffect, useState } from "react";

interface CustomPlanSidebarProps {
    closeSidebar: () => void;
    sidebarOpen: boolean;
    buildingModelUid: string;
    currentCustomPlanName: string;
    currentCarbonReductionPlanUid: string;
}

function CustomPlanSidebar({
    closeSidebar,
    sidebarOpen,
    buildingModelUid,
    currentCustomPlanName,
    currentCarbonReductionPlanUid,
}: CustomPlanSidebarProps) {
    const [customPlanName, setCustomPlanName] = useState<string>(
        currentCustomPlanName
    );

    useEffect(() => {
        setCustomPlanName(currentCustomPlanName);
    }, [currentCustomPlanName]);

    const { mutate } = useUpdateCustomPlanName();

    const onClickCancel = () => {
        setCustomPlanName("");
        closeSidebar();
    };

    const onClickApply = () => {
        mutate(
            {
                carbonReductionPlanId: currentCarbonReductionPlanUid,
                name: customPlanName,
                buildingModelUid,
            },
            {
                onSuccess: () => {
                    closeSidebar();
                },
            }
        );
    };

    return (
        <Sidebar
            onCancel={closeSidebar}
            sidebarOpen={sidebarOpen}
            title="Edit Project"
        >
            <div className="filter-container">
                <div className="filter-container">
                    <Header size="small">Custom Plan Name</Header>
                    <input
                        value={customPlanName || ""}
                        className="filter-input"
                        onChange={(e) => setCustomPlanName(e.target.value)}
                        placeholder="Edit custom plan name"
                        maxLength={50}
                    />
                </div>
            </div>

            {/* Footer */}
            <div className="filters-sidebar__footer">
                <div className="filters-sidebar__footer__buttons">
                    <Button type="primary" onClick={onClickApply}>
                        Apply
                    </Button>
                    <Button onClick={onClickCancel} type="link">
                        Cancel
                    </Button>
                </div>
            </div>
        </Sidebar>
    );
}

export default CustomPlanSidebar;
