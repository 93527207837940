import UserManagementMenu from "components/organisms/Menu/UserManagementMenu";
import { useSetRoles } from "mutations/Roles/useSetRolesMutation";
import { useAddUsers } from "mutations/Users/useAddUsers";
import { useRemoveUsers } from "mutations/Users/useRemoveUsers";
import { RoleActions } from "utils/enums";

interface UserActionsProps {
    userId: string;
    roles: string[];
    email: string;
    isActive: boolean;
}

// Todo: This will be simplified when we add more roles. We'll create a modal or menu to manage new roles with conditional rendering
function UserActions({ userId, roles, email, isActive }: UserActionsProps) {
    const { mutate: mutateSetRoles, isLoading: setRolesIsLoading } = useSetRoles();
    const { mutate: mutateRemoveUser, isLoading: removeUserIsLoading } = useRemoveUsers();
    const { mutate: mutateAddUsers } = useAddUsers();

    const handleOptionClick = (action: string) => {
        if (action === RoleActions.MakeMember || action === RoleActions.MakeAdmin) {
            let newRoles = [...roles]

            if (action.includes("Admin")) {
                if (!newRoles.includes("Admin")) {
                    newRoles.push("Admin");
                }
            }

            if (action.includes("Member")) {
                newRoles = newRoles.filter((role) => role !== "Admin")
                if (!newRoles.includes("Member")) {
                    newRoles.push("Member");
                }
            }

            mutateSetRoles({ userIds: [userId], roles: newRoles });
        }

        if (action === RoleActions.MakeAudetteAdmin || action === RoleActions.RemoveAudetteAdmin) {
            let newRoles = [...roles]

            if (action === RoleActions.MakeAudetteAdmin) {
                if (!newRoles.includes("Audette Admin")) {
                    newRoles.push("Audette Admin");
                }
            }
            if (action === RoleActions.RemoveAudetteAdmin) {
                newRoles = newRoles.filter((role) => role !== "Audette Admin")
                if (!newRoles.includes("Member")) {
                    newRoles.push("Member");
                }
            }
            mutateSetRoles({ userIds: [userId], roles: newRoles });
        }

        if (action === RoleActions.Remove){
            mutateRemoveUser({ userIds: [userId] });
        }

        if (action === RoleActions.Reinvite){
            mutateRemoveUser({ 
                userIds: [userId],
                doNotInvalidate: true
            }, {
                onSuccess: () => {
                    mutateAddUsers({ 
                        emails: [email],
                        userIsReinvited: true
                    });
                }
            });
        }
    };

    return (
        <UserManagementMenu
            email={email}
            roles={roles}
            isActive={isActive}
            onOptionClick={handleOptionClick}
            setRolesIsLoading={setRolesIsLoading}
            removeUserIsLoading={removeUserIsLoading}
        />
    );
}

export default UserActions;