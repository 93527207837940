import { Header } from "components/atoms/Typography";
import TotalCarbonEmissionsAndEnergyCard from "./TotalCarbonEmissionsAndEnergyCard";
import DecarbonizationForecastChart from "./DecarbonizationForecastChart";

interface PerformanceImpactProps {
    carbonReductionMeasureType: string;
    carbonReductionMeasureCategory: string;
}

function PerformanceImpact({
    carbonReductionMeasureType,
    carbonReductionMeasureCategory,
}: PerformanceImpactProps) {
    return (
        <div className="project-report__section">
            <Header size="medium">Performance impact</Header>
            <div className="performance-impact">
                <TotalCarbonEmissionsAndEnergyCard
                    carbonReductionMeasureType={carbonReductionMeasureType}
                />
                <DecarbonizationForecastChart
                    carbonReductionMeasureCategory={
                        carbonReductionMeasureCategory
                    }
                    carbonReductionMeasureType={carbonReductionMeasureType}
                />
            </div>
        </div>
    );
}

export default PerformanceImpact;