import { atom, useRecoilState } from "recoil";

interface MeasureSidebarState {
    isOpen: boolean;
}

const measureSidebarStateAtom = atom<MeasureSidebarState>({
    key: "measureSidebarState",
    default: {
        isOpen: false
    }
});

const useMeasureSidebarState = () => {
    const [state, setState] = useRecoilState(measureSidebarStateAtom);

    return {
        state,
        setState,
    };
};

export default useMeasureSidebarState;