import { ResetIcon } from "components/atoms/Icon";
import { Paragraph } from "components/atoms/Typography";
import DangerousModal from "components/molecules/DangerousModal";
import useAlert from "hooks/useAlert";
import { useRemodelBuildingModels } from "mutations/useRemodelBuildingModels";
import posthog from "posthog-js";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useMemo, useState } from "react";
import { showMutationAlert } from "utils/helpers";

interface RemodelBuildingsViewProps {
    selectedBuildingModelUids: string[];
    clearSelectedRows: () => void;
}

function RemodelBuildingsView({
    selectedBuildingModelUids,
    clearSelectedRows,
}: RemodelBuildingsViewProps) {
    const remodelBuildingModels = useRemodelBuildingModels();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const featureEnabled = useFeatureFlagEnabled("bulk-remodel");

    const handleRemodelBuildings = () => {
        remodelBuildingModels.mutate(selectedBuildingModelUids, {
            onSuccess: () => {
              clearSelectedRows();
              setConfirmationModalOpen(false);
            },
        });
        posthog.capture("Buildings bulk-remodelled", {
            remodelled_buildings_count: -selectedBuildingModelUids.length,
        });
    };

    const buildRemodelBuildingsModalOptions = () => ({
        title: `Remodel building`,
        body: (
            <>
                Remodelling will update the building to the latest standards, which
                may lead to changes in energy and emission figures, Do you wish to
                proceed?
            </>
        ),
        deleteButtonText: `Remodel buildings`,
    });
    
    const modalOptions = useMemo(() => buildRemodelBuildingsModalOptions(), []);

    if (!featureEnabled) {
        return <div style={{ display: "none" }} />;
    }

    const alert = useAlert();

    const remodelClickHandler = () => {
        if (selectedBuildingModelUids.length <= 100) {
            setConfirmationModalOpen(true);
        } else {
            showMutationAlert('You can remodel up to 100 buildings at a time. Please select fewer buildings.', alert);
        }
    }

    return (
        <>
            <button
                id="bulk-remodel-button"
                type="button"
                onClick={remodelClickHandler}
            >
                <ResetIcon />
                <Paragraph>
                    Remodel ({selectedBuildingModelUids.length})
                </Paragraph>
            </button>
            <DangerousModal
                open={confirmationModalOpen}
                onCancel={() => setConfirmationModalOpen(false)}
                onDelete={handleRemodelBuildings}
                options={modalOptions}
                loading={remodelBuildingModels.isLoading}
                styleType="primary"
            />
        </>
    );
}

export default RemodelBuildingsView;