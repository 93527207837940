import { request } from "graphql-request";
import { graphql } from "gql/gql";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const queryFilteredBuildings = (
    accessToken: string,
    data: {
        reportFiltersetUid: string | null;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            reportFiltersetUid: data.reportFiltersetUid,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data) {
            return data;  
        }
        throw new Error("Error getting filtered buildings");
    })
    .catch((error) => {
        console.error("GraphQL Error:", error);
        throw error; 
    });

const query = graphql(`
    query filteredBuildings($reportFiltersetUid: Uuid) {
    filteredBuildings(reportFiltersetUid:  $reportFiltersetUid) {
        buildingModelUid
        buildingName
        propertyName
        grossFloorArea
        buildingArchetype
        stateProvince
        streetAddress
        postalZipCode
        city
        country
        recommendedCrp { 
            carbonReductionPlanUid
            carbonReductionMeasures {
                annualEnergyConsumptionTotal
                annualMeanCarbonEmissionSavings
                annualMeanUtilityCostSavings
                carbonReductionMeasureCategory
                carbonReductionMeasureType
                energyConsumptionSavingsElectricity
                energyConsumptionSavingsNaturalGas
                energyConsumptionSavingsTotal
                incrementalCost
                internalRateOfReturnTotal
                lifetimeCarbonEmissionSavings
                lifetimeCarbonTaxSavings
                lifetimeCarbonTaxSavingsMean
                lifetimeUtilityCostSavings
                lifetimeUtilityCostSavingsMean
                likeForLikeCost
                marginalAbatementCostTotal
                measureCost
                measureLife
                netPresentValueIncremental
                netPresentValueTotal
                returnOnInvestmentTotal
                simplePaybackYearsTotal
                yearApplied
            }
        }
    }
}
`);

export default queryFilteredBuildings;
