import { useTotalCarbonEmissionsCard } from "queries/BuildingReport/Project/useTotalCarbonEmissionsCard";
import { useTotalEnergyConsumptionCard } from "queries/BuildingReport/Project/useTotalEnergyConsumptionCard";
import { useMemo } from "react";
import { CardErrorState } from "components/molecules/ErrorStates";
import { Header } from "components/atoms/Typography";
import { buildTotalCarbonEmissionSavingsCardStruct, LargeCardLoading } from "../ProjectsReport.helper";
import ProjectCardSection from "./ProjectCardSection";

interface TotalCarbonEmissionsCardProps {
    carbonReductionMeasureType: string;
}

function TotalCarbonEmissionsAndEnergyCard({
    carbonReductionMeasureType,
}: TotalCarbonEmissionsCardProps) {
    const {
        data: carbonEmissionsData,
        isLoading: carbonEmissionsLoading,
        isError: carbonEmissionsError,
    } = useTotalCarbonEmissionsCard(carbonReductionMeasureType);

    const {
        data: energyData,
        isLoading: energyLoading,
        isError: energyError,
    } = useTotalEnergyConsumptionCard(carbonReductionMeasureType);

    const cardData = useMemo(() => {
        if (!carbonEmissionsData || !energyData) return undefined;
        return buildTotalCarbonEmissionSavingsCardStruct(
            carbonEmissionsData,
            energyData
        );
    }, [carbonEmissionsData, energyData]);

    if (carbonEmissionsError || energyError) return <CardErrorState />;
    if (energyLoading || carbonEmissionsLoading || !cardData)
        return <LargeCardLoading />;

    return (
        <div className="large-card">
            <Header size="small">Energy & Emissions savings</Header>
            {cardData.map((section) => (
                <ProjectCardSection key={section.title.key} data={section} />
            ))}
        </div>
    );
}

export default TotalCarbonEmissionsAndEnergyCard;