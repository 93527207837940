import useAlert from "hooks/useAlert";
import { showMutationAlert } from "utils/helpers";
import "./index.css";
import { useUser } from "hooks/useUser";
import { useUpdateActiveOrganization } from "mutations/useUpdateActiveOrganization";
import HeaderNavBar from "./HeaderNavBar";

function Header() {

    const alert = useAlert();

    const { data: user, isError, isLoading } = useUser(); 
    const { mutate } = useUpdateActiveOrganization();

    const activeOrganization = user?.activeOrganization?.organizationName;
    
    const memberOrganizations: CustomerAccount[] = user?.memberOrganizations || [];
    
    if (isLoading) {
        return (
            <HeaderNavBar 
                memberOrganizations={[]}
                activeOrganization=""
            />
        );  
    }

    if (isError || !user) {
        const errors = getError("ERR-LOADING-USER", "Error loading user information.");
        showMutationAlert(errors, alert);
    }

    if (!memberOrganizations || memberOrganizations.length === 0) {
        const errors = getError("ERR-NO-CUST-ACCOUNT", "No customer account found - Please contact Audette");
        showMutationAlert(errors, alert);
    } else if (!activeOrganization) {
        const defaultOrganizationUid = memberOrganizations[0].organizationUid;

        mutate({ activeOrganizationUid: defaultOrganizationUid });
    }


    return (
        <HeaderNavBar 
            memberOrganizations={memberOrganizations}
            activeOrganization={activeOrganization || ""}
        />
    )
}



function getError(errorCode: string, description: string) {
    return {
        response: {
            errors: [
                { 
                    errorCode,
                    description 
                }
            ]
        }
    };
}

export default Header;
