    import { Button } from "components/atoms/Button";
    import { Header } from "components/atoms/Typography";
    import Sidebar from "components/molecules/Sidebar";
    import { useBuildingModel } from "hooks/Building/useBuildingModel";
    import { useUpdateCustomPlanMeasures } from "mutations/CustomPlans/useUpdateCustomPlanMeasures";
    import { useEffect, useState } from "react";
    import useCustomPlanSidebarState from "recoilStore/CustomPlan/useCustomPlanSidebarState";

    interface MeasuresSidebarProps {
        closeSidebar: () => void;
        sidebarOpen: boolean;
        buildingModelUid: string;
        currentMeasureYear: number;
        currentCustomPlan: CurrentCustomPlan;
        currentCarbonReductionPlanUid: string;
    }

    function MeasuresSidebar({
        closeSidebar,
        sidebarOpen,
        buildingModelUid,
        currentMeasureYear,
        currentCustomPlan,
        currentCarbonReductionPlanUid,
    }: MeasuresSidebarProps) {
        const [measureYear, setMeasureYear] = useState<number>(currentMeasureYear);

        const { data: buildingModel } = useBuildingModel(buildingModelUid);
        const { state: customPlanSidebarState } = useCustomPlanSidebarState();
        const { mutate, isLoading } = useUpdateCustomPlanMeasures();

        useEffect(() => {
            setMeasureYear(currentMeasureYear);
        }, [currentMeasureYear]);

        const onClickCancel = () => {
            closeSidebar();
        };

        const onClickApply = () => {
            const selectedMeasureType =
                customPlanSidebarState.selectedRows[0].carbonReductionMeasureType;

            // Looking at whick plan from the all custom plans is selected
            const targetPlanIndex =
                buildingModel?.buildingModel.carbonReductionPlans.findIndex(
                    (plan) => plan?.id === currentCarbonReductionPlanUid
                );

            if (targetPlanIndex === undefined || targetPlanIndex === -1) {
                console.error(
                    "No matching carbon reduction plan found for the selected row"
                );
                return;
            }
            const planToModify =
                buildingModel?.buildingModel.carbonReductionPlans[targetPlanIndex];

            if (!planToModify) {
                console.error("Plan to modify not found");
                return;
            }

            const updatedCarbonReductionMeasures =
                planToModify.plan?.carbonReductionMeasures?.map((measure) => {
                    if (
                        measure &&
                        measure.carbonReductionMeasureType === selectedMeasureType
                    ) {
                        return {
                            ...measure,
                            yearApplied: measureYear,
                        };
                    }
                    return measure;
                });

            const updatedPlan = {
                ...planToModify,
                plan: {
                    ...planToModify.plan,
                    carbonReductionMeasures: updatedCarbonReductionMeasures,
                },
            };


            const updatedPlanTypedForMutation =
                updatedPlan.plan.carbonReductionMeasures?.map(
                    (crm): CarbonReductionMeasureInput => ({
                        year: crm?.yearApplied || 1900,
                        crmName: crm?.carbonReductionMeasureType || "",
                    })
            );

            const updatedPlanTypedForMutationFiltered = updatedPlanTypedForMutation?.filter((crm) => 
                crm.crmName !== "No Action" && crm.crmName !== "Base building state"
            );
            
            mutate(
                {
                    customPlanId: updatedPlan.id,
                    buildingModelUid,
                    measures: updatedPlanTypedForMutationFiltered || [],
                },
                {
                    onSuccess: () => {
                        closeSidebar();
                    },
                }
            );

        };
        
        return (
            <Sidebar
                onCancel={closeSidebar}
                sidebarOpen={sidebarOpen}
                title="Edit Measure"
            >
                <div className="filter-container">
                    <div className="filter-container">
                        <Header size="small">Implementation Year</Header>
                        <input
                            key="yearBuiltOriginal"
                            required={true}
                            type="number"
                            value={measureYear || 1900}
                            className="filter-input"
                            onChange={(e) => setMeasureYear(Number(e.target.value))}
                            placeholder="Edit measure year"
                            maxLength={50}
                        />
                    </div>
                </div>

                {/* Footer */}
                <div className="filters-sidebar__footer">
                    <div className="filters-sidebar__footer__buttons">
                        <Button 
                            type="primary" 
                            onClick={onClickApply}
                            disabled={isLoading}
                            >
                            {isLoading ? "Loading" : 'Apply'}
                        </Button>

                        {!isLoading &&
                            <Button 
                                onClick={onClickCancel} 
                                type="link"
                                disabled={isLoading}
                            >
                                Cancel
                            </Button>
                        }
                    </div>
                </div>
            </Sidebar>
        );
    }

    export default MeasuresSidebar;
