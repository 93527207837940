import { Header, Paragraph } from "components/atoms/Typography";
import EmptyBuildingSVG from "./EmptyBuildingSVG";

interface EmptyBuildingReportProps {
    isLoading?: boolean;
}

function EmptyBuildingReport({ isLoading = false }: EmptyBuildingReportProps) {
    return (
        <div className="empty-building-report">
            <div className="empty-building-report__title">
                <Header size="small">
                    {isLoading
                        ? "Loading baseline and recommendations..."
                        : "Complete building profile to get decarbonization insights"}
                </Header>
                {isLoading && (
                    <Paragraph style={{ color: "var(--audette-gray-500)" }}>
                        If this is taking too long, try refreshing
                    </Paragraph>
                )}
            </div>
            <EmptyBuildingSVG />
        </div>
    );
}

export default EmptyBuildingReport;