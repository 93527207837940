import { useBuildingModelHeaderQuery } from "queries/useBuildingModelHeaderQuery";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { assembleBuildingAddress, getDisplayInfo } from "utils/formatting";
import { getDataCoverageWarnings } from "utils/helpers";
import { InlineBoldParagraph, Paragraph } from "components/atoms/Typography";
import { BuildingIcon, EditIcon, FundIcon, PinIcon, PlusIcon, WarningCountIcon } from "components/atoms/Icon";
import { Button } from "components/atoms/Button";
import { useDeleteBuildingModels } from "mutations/useDeleteBuildingModels";
import { DeleteButton } from "components/molecules/DangerousButtons";
import posthog from "posthog-js";
import { UserPermissions } from "utils/enums";
import BuildingName from "./BuildingName";
import BuildingTags from "../BuildingTags";
import PropertyDropdown from "../PropertyDropdown";

interface BaselineBuildingReportHeaderProps {
    onClickViewProfile: () => void;
    onClickCreateCustomPlan: () => void;
    hasPermission: (permission: UserPermissions) => boolean
    setIsCustomPlanSidebarOpen: (isOpen: boolean) => void;
    isCustomPlanOpen: boolean;
}

function BuildingPageHeader({
    onClickViewProfile,
    onClickCreateCustomPlan,
    hasPermission,
    setIsCustomPlanSidebarOpen,
    isCustomPlanOpen
}: BaselineBuildingReportHeaderProps) {
    const { buildingModelUid } = useParams();
    if (!buildingModelUid)
        throw new Error("BuildingModelUid param needs to be defined");

    const {
        data: buildingModelHeader,
        isLoading,
        error,
    } = useBuildingModelHeaderQuery(buildingModelUid);

    const dataCoverageWarnings = useMemo(
        () =>
            buildingModelHeader?.dataCoverage
                ? getDataCoverageWarnings(buildingModelHeader.dataCoverage)
                : undefined,
        [buildingModelHeader]
    );

    const hasEquipmentSizeAndYearCount =
        buildingModelHeader?.dataCoverage.equipmentDataHasSizeAndYear ===
            false && buildingModelHeader?.dataCoverage.equipmentDataUploaded
            ? 1
            : 0;

    if (error) return <>error</>;

    if (isLoading || !buildingModelHeader)
        return (
            <div
                className="baseline-building-report-header"
                style={{ height: "150px" }}
            >
                <div style={{ display: "grid", gap: "16px", width: "100%" }}>
                    <div
                        style={{
                            width: "30%",
                            height: "16px",
                            borderRadius: "8px",
                        }}
                        className="loading"
                    />
                    <div
                        style={{
                            width: "20%",
                            height: "16px",
                            borderRadius: "8px",
                        }}
                        className="loading"
                    />
                </div>
                <div
                    style={{
                        width: "20%",
                        height: "16px",
                        borderRadius: "8px",
                    }}
                    className="loading"
                />
            </div>
        );

    const { location, dataCoverage } = buildingModelHeader;
    const fullAddress = assembleBuildingAddress(location);

    const { formatFunction } = getDisplayInfo("dataCoverage");

    const warningCount = dataCoverageWarnings
        ? Object.values(dataCoverageWarnings).filter(Boolean).length +
          hasEquipmentSizeAndYearCount
        : 0;

    return (
        <div className="baseline-building-report-header">
            <div className="baseline-building-report-header--building-info">
                <div className="building-name-and-tags">
                    <BuildingName
                        buildingName={buildingModelHeader.buildingName}
                        buildingModelUid={buildingModelUid}
                    />
                    {formatFunction(dataCoverage)}
                    <BuildingTags
                        buildingModelUid={buildingModelUid}
                        defaultTags={buildingModelHeader.tags}
                    />
                </div>
                <div className="building-header-property-and-location">
                    <PropertyDropdown
                        buildingModelUid={buildingModelUid}
                        propertyName={buildingModelHeader.propertyName}
                    />
                    <FundName fundName={buildingModelHeader.fundName} />
                    <Paragraph
                        style={{
                            color: "var(--audette-gray-500)",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <PinIcon />
                        <span style={{ marginLeft: "4px" }}>{fullAddress}</span>
                    </Paragraph>
                </div>
            </div>
            <div className="baseline-building-report-header__menu-items">
                <div style={{ display: "flex", gap: "10px" }}>
                    {hasPermission(UserPermissions.UseInternalTools) && (
                        <>
                            {isCustomPlanOpen && (
                                <Button
                                    type="secondary"
                                    onClick={() => setIsCustomPlanSidebarOpen(true)}
                                    icon={<EditIcon />}
                                    id="create-custom-plan-button"
                                >
                                    Edit
                                </Button>
                            )}
                            <Button
                                type="secondary"
                                onClick={() => onClickCreateCustomPlan()}
                                icon={<PlusIcon />}
                                id="create-custom-plan-button"
                                >
                                Custom Plan
                            </Button>
                        </>
                    )}
                    <div style={{ position: "relative", display: "inline-block" }}>
                        <Button
                            type="secondary"
                            onClick={() => onClickViewProfile()}
                            icon={<BuildingIcon />}
                            id="building-profile-button"
                        >
                            Profile
                        </Button>
                        {warningCount > 0 && (
                            <WarningCountIcon
                                style={{
                                    position: "absolute",
                                    top: "-8px",
                                    right: "-8px",
                                }}
                            />
                        )}
                    </div>
                </div>
                <DeleteBuilding
                    buildingModelUid={buildingModelUid}
                    buildingName={buildingModelHeader.buildingName}
                />
            </div>
        </div>
    );
}

interface FundNameProps {
    fundName: string | null;
}

function FundName({ fundName }: FundNameProps) {
    return (
        <Paragraph
            style={{ display: "flex", alignItems: "center", gap: "8PX" }}
        >
            <FundIcon />
            {fundName || "No fund"}
        </Paragraph>
    );
}

interface DeleteBuildingProps {
    buildingModelUid: string;
    buildingName: string;
}

function DeleteBuilding({
    buildingModelUid,
    buildingName,
}: DeleteBuildingProps) {
    const navigate = useNavigate();

    const onSuccess = () => navigate("/portfolio");

    const { mutate: deleteBuilding } = useDeleteBuildingModels(onSuccess);

    const options = useMemo(() => buildOptions(buildingName), [buildingName]);

    return (
        <DeleteButton
            onClick={() => {
                deleteBuilding([buildingModelUid]);
                posthog.capture("Buildings deleted", {
                    added_buildings_count: -1,
                });
            }}
            options={options}
        />
    );
}


const buildOptions = (buildingName: string) => ({
    popup: {
        buttonText: "Delete building",
    },
    modal: {
        title: "Delete building?",
        body: (
            <>
                This will{" "}
                <InlineBoldParagraph>
                    permanently remove {buildingName} building
                </InlineBoldParagraph>
                . Do you wish to proceed?
            </>
        ),
        deleteButtonText: "Delete building",
        id: "delete-building-modal",
    },
});

export default BuildingPageHeader;
