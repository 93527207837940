import { useUser } from "hooks/useUser";
import { UserPermissions } from "utils/enums";

export function usePermissions() {
  const { data: user, isError, isLoading, isFetching } = useUser();
  
  // Check for one permission
  const hasPermission = (permission: UserPermissions): boolean => {
    if (isError) return false;
    if ((isLoading || isFetching) && !user) return false; 

    return user?.permissions?.includes(permission) || false;
};

  // Check for one out of multiple permissions
  const hasAnyPermission = (permissions: UserPermissions[]): boolean => {
    if (isError || isLoading || isFetching || !user) {
      return false;
    }
    return permissions.some(permission => user.permissions?.includes(permission));
  };

  // Check for multiple permissions
  const hasAllPermissions = (permissions: UserPermissions[]): boolean => {
    if (isError || isLoading || isFetching || !user) {
      return false;
    }
    return permissions.every(permission => user.permissions?.includes(permission));
  };

  return {
    hasPermission,
    hasAnyPermission,
    hasAllPermissions,
    isLoading,
    isError,
    isFetching
  };
}