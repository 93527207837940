import { CSSProperties, ReactNode } from "react";
import MUITheme from "components/MUITheme";
import MUITooltip from "@mui/material/Tooltip";
import { InfoIcon } from "../Icon";
import { Paragraph } from "../Typography";

import "./Tooltip.scss";

interface TooltipProps {
    tooltipBody: string | ReactNode;
    style?: CSSProperties;
    children?: ReactNode;
}

function Tooltip({ tooltipBody, style, children }: TooltipProps) {
    const tooltipBodyIsStringType = typeof tooltipBody === "string";

    return (
        <MUITheme>
            <MUITooltip
                title={
                    tooltipBodyIsStringType ? (
                        <Paragraph size="small" style={{ padding: "16px" }}>
                            {tooltipBody}
                        </Paragraph>
                    ) : (
                        <div style={{ padding: "16px" }}>{tooltipBody}</div>
                    )
                }
                placement="top"
                style={{ padding: 0 }}
                leaveDelay={50}
                PopperProps={tooltipPopperConfig}
            >
                <div style={{ display: "grid", placeItems: "center" }}>
                    {children || (
                        <div className="tooltip" style={style}>
                            <InfoIcon />
                        </div>
                    )}
                </div>
            </MUITooltip>
        </MUITheme>
    );
}

const tooltipPopperConfig = {
    popperOptions: {
        modifiers: [
            {
                name: "flip",
                options: {
                    fallbackPlacements: ["bottom"],
                },
            },
        ],
    },
};

export default Tooltip;
