// hooks
import { useEffect, useMemo, useState } from "react";
import {  useParams, useSearchParams } from "react-router-dom";
import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";

// components
import { useBuildingModelHeaderQuery } from "queries/useBuildingModelHeaderQuery";
import { usePermissions } from "hooks/usePermissions";
import { UserPermissions } from "utils/enums";
import ViewTab from "components/atoms/ViewTab";
import { ErrorPage } from "pages/ErrorState";
import { useBuildingModel } from "hooks/Building/useBuildingModel";
import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import CreateCustomPlanModal from "components/organisms/Modals/CreateCustomPlanModal/createCustomPlanModal";
import CustomPlanSidebar from "components/organisms/CustomPlanSidebar/CustomPlanSidebar";
import MeasuresSidebar from "components/organisms/MeasureSidebar/MeasureSidebar";
import useMeasureSidebarState from "recoilStore/CustomPlan/useMeasureSidebarState";
import useCustomPlanSidebarState from "recoilStore/CustomPlan/useCustomPlanSidebarState";

import BuildingSidebar from "./BuildingSidebar";
import BaselineReport from "./BaselineReport";
import DecarbonizationReport from "./DecarbonizationReport";
import ProjectsReport from "./ProjectReport/ProjectsReport";

// css
import "./BuildingPage.scss";
import BuildingPageHeader from "./BuildingPageHeader/BuildingPageHeader";
import CustomPlan from "./CustomPlan/CustomPlan";
import EmptyBuildingReport from "./EmptyBuildingReport";


function BuildingPage() {
    // Hooks
    const { buildingModelUid } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    // Recoil store
    const { 
        state: measureSidebarState, 
        setState: setMeasureSidebarState  
    } = useMeasureSidebarState();
    const { state: customPlanSidebarState } = useCustomPlanSidebarState();

    // State Variables
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isCustomPlanOpen, setIsCustomPlanOpen] = useState(false);
    const [isCustomPlanSidebarOpen, setIsCustomPlanSidebarOpen] = useState(false);

    const [currentCustomPlanDetails, setCurrentCustomPlanDetails] = useState<{
        id: string;
        plan: any;
        name: string;
    } | null>(null);

    const [currentActiveTab, setCurrentActiveTab] = useState(
        searchParams.get("tab") || "currentState"
    );

    // Query Params
    const carbonReductionMeasureType = searchParams.get(
        "carbonReductionMeasureType"
    );
    const carbonReductionMeasureCategory = searchParams.get(
        "carbonReductionMeasureCategory"
    );

    if (!buildingModelUid)
        throw new Error("BuildingModelUid param needs to be defined"); // TODO: create a custom hook for this


    const { data: buildingModel } = useBuildingModel(buildingModelUid)

    const { hasPermission, isFetching } = usePermissions();
    const { loadingState } = useBuildingLoadingState(buildingModelUid);

    const handleOnViewProfileClick = () => setIsSidebarOpen((show) => !show);
    const handleOnClose = () => setIsSidebarOpen(false);
    const handleCreateCustomPlan = () => { setModalIsOpen(true); };

    const defaultTabClick = (tab: string) => {
        setCurrentActiveTab(tab);
        setIsCustomPlanOpen(false);
    }
    
    const customPlansTabClick = (plan: any) => {
        setCurrentCustomPlanDetails({
            id: plan.id,
            plan: plan.plan,
            name: plan.name,
        });
        setIsCustomPlanOpen(true);
        setCurrentActiveTab(plan?.id);
    }

    if (!buildingModelUid)
        throw new Error("BuildingModelUid param needs to be defined");

    const {
        isLoading: headerIsLoading,
        error: headerError,
        data: headerData,
    } = useBuildingModelHeaderQuery(buildingModelUid);

    const buildingIsProcessingOrError = useMemo(
        () =>
            headerData?.dataCoverage.modellingStatus === "processing" ||
            headerData?.dataCoverage.modellingStatus === "error" ||
            !headerData,
        [headerData]
    );

    const customPlans = buildingModel?.buildingModel.carbonReductionPlans || [];
    const grossFloorArea = buildingModel?.buildingModel.grossFloorArea || 0;
    const carbonReductionPlanUid = buildingModel?.buildingModel.recommendedCrp?.carbonReductionPlanUid || "";

    useEffect(() => {
        const currentParams = Object.fromEntries(searchParams);
        const tabParam = currentParams.tab;
    
        // Check if the tab param matches any custom plan UIDs
        const matchingCustomPlan = customPlans.find(
            plan => plan?.id === tabParam
        );
    
        if (matchingCustomPlan) {
            setCurrentCustomPlanDetails({
                id: matchingCustomPlan.id,
                plan: matchingCustomPlan.plan,
                name: matchingCustomPlan.name,
            });
            setCurrentActiveTab(tabParam);
            setIsCustomPlanOpen(true);
            // Making sure plan is valid for TS typing
            const validPlan = {
                ...matchingCustomPlan.plan,
                carbonReductionMeasures: matchingCustomPlan.plan.carbonReductionMeasures?.filter(
                    (measure): measure is CarbonReductionMeasure => measure != null
                ) || [],
            };
        
            setCurrentCustomPlanDetails({
                id: matchingCustomPlan.id,
                plan: validPlan,
                name: matchingCustomPlan.name,
            });
            
            if (currentParams.tab !== tabParam) {
                setSearchParams(
                    { ...currentParams, tab: tabParam },
                    { replace: true }
                );
            }
        } else if (tabParam && ["currentState", "decarbonization", "projects"].includes(tabParam)) {
            setCurrentActiveTab(tabParam);
            setIsCustomPlanOpen(false);
            
            if (currentParams.tab !== tabParam) {
                setSearchParams(
                    { ...currentParams, tab: tabParam },
                    { replace: true }
                );
            }
        }
    }, [searchParams, customPlans]);

    useEffect(() => {
        const currentParams = Object.fromEntries(searchParams);
        if (currentParams.tab !== currentActiveTab) {
            setSearchParams(
                { ...currentParams, tab: currentActiveTab },
                { replace: true }
            );
        }
    }, [currentActiveTab]);
    
    if (headerError) return <ErrorPage />;

    // To do When we remove the Permission Check for the clients to see the custom plans, remove that Loading Spinner
    if (isFetching) return <LoadingSpinner />;
    return (
        <div className="building-page-with-sidebar">
            <div className="building-page">
                <BuildingPageHeader
                    onClickViewProfile={handleOnViewProfileClick}
                    onClickCreateCustomPlan={handleCreateCustomPlan}
                    hasPermission={hasPermission}
                    setIsCustomPlanSidebarOpen={setIsCustomPlanSidebarOpen}
                    isCustomPlanOpen={isCustomPlanOpen}
                />
                {buildingIsProcessingOrError ? (
                    <EmptyBuildingReport
                        isLoading={
                            headerIsLoading ||
                            loadingState.buildingReportsLoading
                        }
                    />
                ) : (
                    <>
                        <div className="building-page--view-tabs">
                            <ViewTab
                                id="building-baseline-tab"
                                name="Baseline"
                                selected={currentActiveTab === "currentState"}
                                onClick={() =>
                                    defaultTabClick("currentState")
                                }
                            />
                            <ViewTab
                                id="building-recommendations-tab"
                                name="Recommendations"
                                selected={
                                    currentActiveTab === "decarbonization"
                                }
                                onClick={() =>
                                    defaultTabClick("decarbonization")
                                }
                            />
                            <ViewTab
                                id="building-projects-tab"
                                name="Projects"
                                selected={currentActiveTab === "projects"}
                                onClick={() => defaultTabClick("projects")}
                            />
                            {hasPermission(UserPermissions.UseInternalTools) && 
                                customPlans.filter(
                                        (plan): plan is NonNullable<typeof plan> => plan != null
                                ).map((customPlan) => (
                                    <ViewTab
                                        key={customPlan.id}
                                        name={customPlan.name} 
                                        selected={
                                            currentActiveTab === 
                                                customPlan.id
                                        }
                                        onClick={() => customPlansTabClick(customPlan)}
                                    />
                            ))}

                        </div>


                        {currentActiveTab === "currentState" && (
                            <div className="report">
                                <BaselineReport />
                            </div>
                        )}


                        {currentActiveTab === "decarbonization" && (
                            <div className="report">
                                <DecarbonizationReport />
                            </div>
                        )}


                        {currentActiveTab === "projects" && (
                                <ProjectsReport
                                        carbonReductionMeasureTypeUrl={
                                            carbonReductionMeasureType
                                        }
                                        carbonReductionMeasureCategoryUrl={
                                            carbonReductionMeasureCategory
                                        }
                                />
                            )
                        }

                        {isCustomPlanOpen && currentCustomPlanDetails && (
                            <CustomPlan 
                                currentCustomPlan={currentCustomPlanDetails.plan} 
                                grossFloorArea={grossFloorArea}  
                                currentCarbonReductionPlanUid={currentCustomPlanDetails?.id || ""}
                                
                            />
                        )}

                        <CreateCustomPlanModal
                            modalIsOpen={modalIsOpen}
                            onCancel={() => setModalIsOpen(false)}
                            carbonReductionPlanUid={carbonReductionPlanUid}
                            setModalIsOpen={setModalIsOpen} 
                            buildingModelUid={buildingModelUid}   
                        />
                    </>
                )}
            </div>

            <BuildingSidebar
                buildingModelUid={buildingModelUid}
                show={isSidebarOpen}
                onClose={handleOnClose}
                buildingName={headerData?.buildingName || ""}
            />


            {/* Sidebars */}
            <CustomPlanSidebar
                sidebarOpen={isCustomPlanSidebarOpen}
                closeSidebar={() => setIsCustomPlanSidebarOpen(false)}
                buildingModelUid={buildingModelUid}
                currentCustomPlanName={currentCustomPlanDetails?.name || ""}
                currentCarbonReductionPlanUid={currentCustomPlanDetails?.id || ""}
            />

            <MeasuresSidebar 
                sidebarOpen={measureSidebarState.isOpen}
                closeSidebar={() => setMeasureSidebarState({ isOpen: false })}
                buildingModelUid={buildingModelUid}
                // TODO Update to dynamic year
                currentMeasureYear={customPlanSidebarState?.selectedRows[0]?.yearApplied || 2020}
                currentCustomPlan={currentCustomPlanDetails?.plan}
                currentCarbonReductionPlanUid={currentCustomPlanDetails?.id || ""}
            />
        </div>
    );
}


export default BuildingPage;
