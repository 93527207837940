import { BackArrow } from "components/atoms/Icon";
import { Header, Paragraph } from "components/atoms/Typography";
import { useHeader } from "queries/BuildingReport/Project/useHeader";
import { buildHeaderTitleAndUnitsStruct, measureNameToDescriptionMap, ProjectReportHeaderAttribute } from "../ProjectsReport.helper";

interface ProjectReportHeaderProps {
    carbonReductionMeasureType: string;
    handleBackClick: () => void;
}

function ProjectReportHeader({
    carbonReductionMeasureType,
    handleBackClick,
}: ProjectReportHeaderProps) {
    const { isLoading, isError, data } = useHeader(carbonReductionMeasureType);

    if (isError)
        return (
            <div className="project-report-header">
                <div
                    role="button"
                    className="project-report__back-button"
                    onClick={handleBackClick}
                    onKeyDown={handleBackClick}
                    tabIndex={0}
                >
                    <BackArrow />
                    <Header
                        style={{ color: "var(--audette-gray-600)" }}
                        size="small"
                    >
                        Back to projects
                    </Header>
                </div>
                <Header size="medium"> {carbonReductionMeasureType}</Header>
            </div>
        );

    if (isLoading || !data)
        return (
            <div className="project-report-header--loading">
                <div className="loading" style={{ width: "20%" }} />
                <div className="loading" style={{ width: "12%" }} />
                <div className="loading" style={{ width: "13%" }} />
                <div className="loading" style={{ width: "11%" }} />
            </div>
        );

    return (
        <div className="project-report-header">
            <div
                role="button"
                className="project-report__back-button"
                onClick={handleBackClick}
                onKeyDown={handleBackClick}
                tabIndex={0}
            >
                <BackArrow />
                <Header
                    style={{
                        color: "var(--audette-gray-600)",
                    }}
                    size="small"
                >
                    Back to projects
                </Header>
            </div>
            <div>
                <Header size="medium" style={{ marginBottom: "1rem" }}>
                    {carbonReductionMeasureType}
                </Header>

                <div className="project-report-header__row">
                    {buildHeaderTitleAndUnitsStruct(data).map(
                        ({ title, titleValue, icon, unit }) => (
                            <ProjectReportHeaderAttribute
                                key={title}
                                title={title}
                                titleValue={titleValue}
                                unit={unit}
                                icon={icon}
                            />
                        )
                    )}
                </div>
            </div>
            {measureNameToDescriptionMap[carbonReductionMeasureType] && (
                <Paragraph size="small" style={{ maxWidth: "40%" }}>
                    {measureNameToDescriptionMap[carbonReductionMeasureType]}
                </Paragraph>
            )}
        </div>
    );
}

export default ProjectReportHeader;