import BuildingTypeDropdown from "components/molecules/BuildingTypeDropdown";
import EnergyTypeDropdown from "components/molecules/EnergyTypeDropdown";
import FormInput from "components/molecules/FormInput";
import { useEffect, useState } from "react";
import { provinceStateOptions } from "utils/constants";
// import styles from "./Input.module.scss";
interface InputProps {
    attributeKey: string;
    required: boolean;
    type: "string" | "number" | "select";
    value: string | number | undefined;
    setValue: (value: string | number | undefined) => void;
}

function Input({ attributeKey, required, type, value, setValue }: InputProps) {
    const [selectedCountry, setSelectedCountry] = useState<"US" | "Canada">(
        "US"
    );
    const [selectedProvince, setSelectedProvince] = useState<string>("");
    useEffect(() => {
        setSelectedProvince("");
        setValue(undefined);
    }, [selectedCountry]);
    if (attributeKey === "stateProvince") {
        return (
            <div>
                <h3
                    style={{ marginBottom: "var(--size-margin-s)" }}
                    className="medium"
                >
                    State/Province
                    <span style={{ color: "var(--audette-content-negative)" }}>
                        *
                    </span>
                </h3>

                {/* State/Province dropdown */}
                <select
                    value={selectedProvince || ""}
                    className="selector"
                    onChange={(e) => {
                        const provinceValue = e.target.value;
                        setSelectedProvince(provinceValue);
                        setValue(provinceValue);
                    }}
                    required={required}
                >
                    <option value="">Select {selectedCountry === "US" ? "State" : "Province "}</option>
                    {provinceStateOptions[selectedCountry].map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                <button
                    type="button"
                    onClick={(e) =>
                        setSelectedCountry(
                            selectedCountry === "US" ? "Canada" : "US"
                        )
                    }

                    className="audette-button link"
                >
                    Click me to select{" "}
                    {selectedCountry === "US" ? "Provinces" : "States"} 
                </button>

                {/* Commenting error message for now, wil  */}
                {/* {required && !selectedProvince && (
                    <div className={styles.error}>
                        Please select a state/province
                    </div>
                )} */}
            </div>
        );
    }

    if (type === "select" && attributeKey === "buildingArchetype")
        return (
            <BuildingTypeDropdown
                value={value?.toString()}
                setValue={setValue}
                required={true}
            />
        );
    if (type === "select" && attributeKey === "energyType")
        return (
            <EnergyTypeDropdown
                value={value?.toString()}
                setValue={setValue}
                required={true}
            />
        );
    if (type === "string" || type === "number")
        return (
            <FormInput
                value={value || ""}
                setValue={(v) => {
                    if (type === "number") setValue(Number(v));
                    else setValue(v);
                }}
                attributeKey={attributeKey}
                type={type}
                setIsError={() => {}}
                required={required}
            />
        );

    return null;
}

export default Input;