import { useEffect, useMemo, useRef, useState } from "react";
import { useClickOutside } from "hooks/useClickOutside";
import { useCreateBuildingModelTags } from "mutations/useCreateBuildingModelTags";
import { useDeleteBuildingModelTags } from "mutations/useDeleteBuildingModelTags";
import Modal from "components/molecules/Modal";
import { Header, Paragraph } from "components/atoms/Typography";
import { BuildingAddTagsOverlay, Tag } from "pages/BuildingPage/BuildingTags";
import AddEditTagsModalFooter from "./AddEditTagsModalFooter";
import { BuildingsTableData } from "../helpers";

interface AddEditTagsModalProps {
    open: boolean;
    onCancel: () => void;
    tags: string[];
    tagsAreMixed: boolean;
    selectedBuildingRows: BuildingsTableData[];
    setSelectedBuildingRows: (rows: BuildingsTableData[]) => void;
}

function AddEditTagsModal({
    open,
    onCancel,
    tags,
    tagsAreMixed,
    selectedBuildingRows,
    setSelectedBuildingRows,
}: AddEditTagsModalProps) {
    const [isEditingTags, setIsEditingTags] = useState(false);
    const ref = useRef(null);
    useClickOutside(ref, () => setIsEditingTags(false));

    const [localTags, setLocalTags] = useState(tagsAreMixed ? [] : tags);

    useEffect(() => {
        setLocalTags(tagsAreMixed ? [] : tags);
    }, [tags, open]);

    const selectedBuildingUids = useMemo(
        () =>
            selectedBuildingRows.map(
                ({ buildingModelUid }) => buildingModelUid
            ),
        [selectedBuildingRows]
    );

    const { mutate: createTags } =
        useCreateBuildingModelTags(selectedBuildingUids);
    const { mutate: deleteTags } =
        useDeleteBuildingModelTags(selectedBuildingUids);

    const createdTags = useMemo(() => {
        if (tagsAreMixed) return [...localTags];
        return localTags.filter((localTag) => !tags.includes(localTag));
    }, [localTags, tags]);

    const removeTag = (e: any, tag: string) => {
        e.stopPropagation();
        setLocalTags((prev) => {
            const copy = [...prev];
            const index = copy.findIndex((v) => v === tag);
            copy.splice(index, 1);
            return copy;
        });
    };

    const handleSaveTags = () => {
        if (createdTags.length !== 0) {
            const createTagsData = selectedBuildingRows
                .map(({ buildingModelUid, tags }) => {
                    const tagsToCreate = createdTags.filter(
                        (tag) => !tags.includes(tag)
                    );
                    return {
                        tags: tagsToCreate,
                        buildingModelUid,
                    };
                })
                .filter((v) => v.tags.length !== 0);
            createTags(createTagsData);
        }

        if (!tagsAreMixed) {
            // can't remove existing tags when they are mixed
            const tagsRemoved = tags.filter((tag) => !localTags.includes(tag));
            deleteTags(
                selectedBuildingUids.map((buildingModelUid) => ({
                    tags: tagsRemoved,
                    buildingModelUid,
                }))
            );
        }
        setSelectedBuildingRows([]);
        onCancel();
    };

    const deleteAllTags = () => {
        const tagData = selectedBuildingUids.map((buildingModelUid) => ({
            buildingModelUid,
            tags,
        }));
        setSelectedBuildingRows([]);
        deleteTags(tagData);
        onCancel();
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            title={tags.length === 0 ? "Add tags" : "Edit tags"}
            footer={
                <AddEditTagsModalFooter
                    onSave={handleSaveTags}
                    onCancel={onCancel}
                    onDeleteAll={tags.length === 0 ? undefined : deleteAllTags}
                />
            }
        >
            <div
                className="bulk-edit-tags"
                style={{ position: "relative" }}
                ref={ref}
            >
                <Header size="small">Tags</Header>
                <div className="bulk-edit-tags__select">
                    <button
                        type="button"
                        onClick={() => setIsEditingTags(true)}
                    >
                        {localTags.length !== 0 && (
                            <>
                                {localTags.map((tag) => (
                                    <Tag
                                        key={tag}
                                        value={tag}
                                        onClickClose={(e) => removeTag(e, tag)}
                                    />
                                ))}
                            </>
                        )}
                        {localTags.length === 0 && (
                            <Paragraph
                                style={{ color: "var(--audette-gray-400)" }}
                            >
                                Select existing or create new
                            </Paragraph>
                        )}
                    </button>
                </div>
                {tagsAreMixed && (
                    <Paragraph style={{ color: "var(--audette-gray-500)" }}>
                        Selected buildings contain mixed tags, new tags will be
                        added to them.
                    </Paragraph>
                )}
                {isEditingTags && (
                    <BuildingAddTagsOverlay
                        buildingTags={localTags}
                        buildingModelUid="0"
                        setBuildingTags={setLocalTags}
                    />
                )}
            </div>
        </Modal>
    );
}

export default AddEditTagsModal;