import { Delete } from "components/atoms/Icon";
import { InlineBoldParagraph, Paragraph } from "components/atoms/Typography";
import DangerousModal from "components/molecules/DangerousModal";
import { useDeleteBuildingModels } from "mutations/useDeleteBuildingModels";
import posthog from "posthog-js";
import { useMemo, useState } from "react";

interface DeleteProps {
    numberOfSelectedIds: number;
    buildingName?: string;
    clearSelectedRows: () => void;
    selectedBuildingModelUids: string[];
}

function DeleteBuildings({
    numberOfSelectedIds,
    buildingName,
    clearSelectedRows,
    selectedBuildingModelUids,
}: DeleteProps) {
    const { mutate: deleteBuildingModels } = useDeleteBuildingModels();

    const [deleteBuildingsModalIsOpen, setDeleteBuildingsModalIsOpen] =
        useState(false);

    const handleDeleteBuildings = () => {
        deleteBuildingModels(selectedBuildingModelUids);
        posthog.capture("Buildings deleted", {
            added_buildings_count: -selectedBuildingModelUids.length,
        });
        clearSelectedRows();
        setDeleteBuildingsModalIsOpen(false);
    };

    const modalOptions = useMemo(
        () =>
            buildDeleteBuildingsModalOptions(numberOfSelectedIds, buildingName),
        [numberOfSelectedIds, buildingName]
    );

    return (
        <>
            <Paragraph style={{ color: "var(--audette-content-negative)" }}>
                <button
                    id="delete-buildings-button"
                    data-ph-capture-attribute-buildings-count={
                        numberOfSelectedIds
                    }
                    type="button"
                    onClick={() => setDeleteBuildingsModalIsOpen(true)}
                >
                    <Delete />
                    Delete {numberOfSelectedIds}
                </button>
            </Paragraph>
            <DangerousModal
                open={deleteBuildingsModalIsOpen}
                onCancel={() => setDeleteBuildingsModalIsOpen(false)}
                onDelete={handleDeleteBuildings}
                options={modalOptions}
                styleType="primary-negative"
            />
        </>
    );
}


const buildDeleteBuildingsModalOptions = (
    numberOfSelectedIds: number,
    buildingName?: string
) => ({
    title: `Delete building${numberOfSelectedIds > 1 ? "s" : ""}?`,
    body: (
        <>
            This will{" "}
            <InlineBoldParagraph>
                permanently remove{" "}
                {buildingName
                    ? `${buildingName} building`
                    : "all selected buildings"}
            </InlineBoldParagraph>
            . Do you wish to proceed?
        </>
    ),
    deleteButtonText: `Delete building${numberOfSelectedIds > 1 ? "s" : ""}`,
});


export default DeleteBuildings;