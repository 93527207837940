import { useEffect, useMemo, useRef, useState } from "react";
import { useClickOutside } from "hooks/useClickOutside";
import useUniqueTags from "recoilStore/useUniqueTags";
import { useCreateBuildingModelTags } from "mutations/useCreateBuildingModelTags";
import { useDeleteBuildingModelTags } from "mutations/useDeleteBuildingModelTags";
import { Paragraph } from "components/atoms/Typography";
import { AddIcon, EditIcon, CloseButton } from "components/atoms/Icon";
import "./BuildingTags.scss";
import "./BuildingSelectorOverlay.scss";
import { InlineBoldParagraph } from "components/atoms/Typography/Typography";
import { UserPermissions } from "utils/enums";
import { usePermissions } from "hooks/usePermissions";

interface BuildingTagsProps {
    buildingModelUid: string;
    defaultTags: string[];
}

function BuildingTags({ buildingModelUid, defaultTags }: BuildingTagsProps) {
    const [buildingTags, setBuildingTags] = useState<string[]>(defaultTags);

    useEffect(() => {
        setBuildingTags(defaultTags);
    }, [defaultTags]);

    const [isEditingTags, setIsEditingTags] = useState(false);
    const outsideclickref = useRef<HTMLDivElement | null>(null);

    useClickOutside(outsideclickref, () => setIsEditingTags(false));

    const { mutate: createTags } = useCreateBuildingModelTags([
        buildingModelUid,
    ]);
    const { mutate: deleteTags } = useDeleteBuildingModelTags([
        buildingModelUid,
    ]);

    const handleRemoveTags = (tags: string[]) => {
        deleteTags([
            {
                tags,
                buildingModelUid,
            },
        ]);
    };

    const handleAddTags = (tags: string[]) => {
        createTags([
            {
                buildingModelUid,
                tags,
            },
        ]);
    };

    return (
        <div className="building-tags" ref={outsideclickref}>
            {buildingTags.map((tag) => (
                <Tag key={tag} value={tag} />
            ))}
            {buildingTags.length === 0 ? (
                <button
                    className="building-tags__tag"
                    type="button"
                    onClick={() => setIsEditingTags(true)}
                >
                    <AddIcon color="var(--audette-gray-600)" />
                    <Paragraph
                        size="small"
                        style={{ color: "var(--audette-gray-600)" }}
                    >
                        Add tag
                    </Paragraph>
                </button>
            ) : (
                <button
                    type="button"
                    style={{
                        paddingLeft: "4px",
                    }}
                    onClick={() => setIsEditingTags(true)}
                >
                    <EditIcon color="var(--audette-gray-600)" />
                </button>
            )}
            {isEditingTags && (
                <BuildingAddTagsOverlay
                    buildingTags={buildingTags}
                    buildingModelUid={buildingModelUid}
                    setBuildingTags={setBuildingTags}
                    addTags={handleAddTags}
                    deleteTags={handleRemoveTags}
                />
            )}
        </div>
    );
}

interface BuildingAddTagsOverlayProps {
    buildingTags: string[];
    buildingModelUid: string;
    setBuildingTags: React.Dispatch<React.SetStateAction<string[]>>;
    addTags?: (tags: string[]) => void;
    deleteTags?: (tags: string[]) => void;
}

export function BuildingAddTagsOverlay({
    buildingTags,
    setBuildingTags,
    buildingModelUid,
    addTags,
    deleteTags,
}: BuildingAddTagsOverlayProps) {
    const { tags } = useUniqueTags();
    const [allTags, setAllTags] = useState(tags.tags);

    const [newTagValue, setNewTagValue] = useState("");

    const handleRemoveTag = (tag: string) => {
        removeTagFromList(setBuildingTags, tag);
        if (deleteTags) deleteTags([tag]);
    };

    const handleAddTag = (tag?: string) => {
        const newTag = tag || newTagValue;

        if (newTag) {
            addTagToUniqueList(setBuildingTags, newTag);
            addTagToUniqueList(setAllTags, newTag);
            if (addTags) addTags([newTag]);
            setNewTagValue("");
        }
    };

    const inputRef = useRef<HTMLInputElement | null>(null);
    const focusInput = () => {
        inputRef?.current?.focus();
    };
    useEffect(() => {
        focusInput();
    }, [inputRef]);

    const suggestedTags = useMemo(
        () => allTags.filter((tag) => !buildingTags.includes(tag)),
        [allTags]
    );

    return (
        <div className="building-selector-overlay">
            <div className="building-selector-overlay__input">
                {buildingTags.map((tag) => (
                    <Tag
                        key={tag}
                        value={tag}
                        onClickClose={(e) => {
                            handleRemoveTag(tag);
                            e.stopPropagation();
                        }}
                    />
                ))}
                <input
                    ref={inputRef}
                    value={newTagValue}
                    onChange={(event) => {
                        const { value } = event.target;
                        setNewTagValue(value);
                    }}
                    onBlur={focusInput}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            handleAddTag();
                        }
                    }}
                />
            </div>
            <div className="building-selector-overlay__options">
                <Paragraph
                    className="building-selector-overlay__options__select"
                    size="small"
                    style={{ color: "var(--audette-gray-600)" }}
                >
                    Select an option or create one
                </Paragraph>
                {suggestedTags.map((tag) => {
                    const split = splitTag(tag, newTagValue);
                    if (split !== null)
                        return (
                            <button
                                key={buildingModelUid}
                                onClick={() => handleAddTag(tag)}
                                type="button"
                                style={{ display: "block" }}
                            >
                                <Paragraph className="building-selector-overlay__options__select">
                                    <InlineBoldParagraph> {split[0]}</InlineBoldParagraph> 
                                    {split[1]}
                                </Paragraph>
                            </button>
                        );
                    return null;
                })}
                {newTagValue && newTagValue !== "" && (
                    <button
                        onClick={(e) => {
                            handleAddTag();
                            e.stopPropagation();
                        }}
                        type="button"
                    >
                        <Paragraph
                            className="building-selector-overlay__options__select"
                            style={{ color: "var(--audette-gray-500)" }}
                        >
                            Create{" "}
                            <InlineBoldParagraph style={{ color: "var(--audette-black)" }}>
                                {newTagValue}
                            </InlineBoldParagraph>
                        </Paragraph>
                    </button>
                )}
            </div>
        </div>
    );
}


interface TagProps {
    onClickClose?: (e: any) => void;
    value: string;
}

export function Tag({ onClickClose, value }: TagProps) {
    const { hasPermission } = usePermissions();


    const shouldDisplay =
        value !== "audette-benchmark" ||
        hasPermission(UserPermissions.UseInternalTools) &&
        hasPermission(UserPermissions.GrantAudetteAdmin);

    return (
        <div 
            className="building-tags__tag" 
            style={{ display: shouldDisplay ? "flex" : "none" }}
        >
            <Paragraph size="small">{value}</Paragraph>
            {onClickClose && <CloseButton onClick={onClickClose} />}
        </div>
    );
}

// tag helpers

const splitTag = (tag: string, newValue: string) => {
    if (tag.startsWith(newValue)) {
        return [newValue, tag.replace(newValue, "")];
    }
    return null;
};

const addTagToUniqueList = (
    setterFunc: (value: React.SetStateAction<string[]>) => void,
    tag: string
) => {
    setterFunc((prev) => {
        const copy = new Set(prev);
        copy.add(tag);
        return Array.from(copy);
    });
};

const removeTagFromList = (
    setterFunc: (value: React.SetStateAction<string[]>) => void,
    tag: string
) => {
    setterFunc((prev) => {
        const index = prev.indexOf(tag);
        if (index === -1) return prev;
        const copy = [...prev];
        copy.splice(index, 1);
        return copy;
    });
};

export default BuildingTags;
