import { Paragraph } from "components/atoms/Typography";
import { AddIcon, EditIcon } from "components/atoms/Icon";
import { useState } from "react";
import { BuildingsTableData } from "../helpers";
import AddEditTagsModal from "./AddEditTagsModal";

interface AddEditTagsProps {
    selectedTagsAreMixed: boolean;
    selectedTags: string[];
    selectedBuildingRows: BuildingsTableData[];
    setSelectedBuildingRows: (rows: BuildingsTableData[]) => void;
}

function AddEditTags({
    selectedTagsAreMixed,
    selectedTags,
    selectedBuildingRows,
    setSelectedBuildingRows,
}: AddEditTagsProps) {
    const [addTagsModalIsOpen, setAddTagsModalIsOpen] = useState(false);
    return (
        <>
            <button type="button" onClick={() => setAddTagsModalIsOpen(true)}>
                {selectedTags.length === 0 ? (
                    <AddIcon color="var(--audette-gray-600)" />
                ) : (
                    <EditIcon />
                )}
                <Paragraph>
                    {selectedTags.length === 0 ? "Add tags" : "Edit tags"}
                </Paragraph>
            </button>
            <AddEditTagsModal
                open={addTagsModalIsOpen}
                onCancel={() => setAddTagsModalIsOpen(false)}
                tags={selectedTags}
                tagsAreMixed={selectedTagsAreMixed}
                selectedBuildingRows={selectedBuildingRows}
                setSelectedBuildingRows={setSelectedBuildingRows}
            />
        </>
    );
}

export default AddEditTags;