import queryBuildingModel from "ApiLayer/Building/queryBuildingModel";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { useQuery } from "react-query";
import { QUERY_STALE_TIME } from "utils/constants";
import { showMutationAlert } from "utils/helpers";

export const buildingModelQueryKey = ["buildingModel"];

export const useBuildingModel = (buildingModelUid: string ) => {
    const accessToken = useAccessToken();
    const alert = useAlert();

    return useQuery({
        queryKey: [...buildingModelQueryKey, buildingModelUid], 
        staleTime: QUERY_STALE_TIME,
        queryFn: async () => queryBuildingModel(
            accessToken,
            { buildingModelUid }
        ),
        suspense: true,
        onError: (error) => {
            showMutationAlert(error, alert);
        },
    });
};
