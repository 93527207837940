import mutationUpdateCustomPlanMeasures from "ApiLayer/CustomPlans/queryUpdateCustomPlanMeasures";
import { buildingModelQueryKey } from "hooks/Building/useBuildingModel";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { useMutation, useQueryClient } from "react-query";
import { showMutationAlert } from "utils/helpers";

export interface UpdateCustomPlanMeasures {
    customPlanId: string;
    measures: CarbonReductionMeasureInput[];
    buildingModelUid: string;
}

export const useUpdateCustomPlanMeasures = () => {
    const accessToken = useAccessToken();
    const alert = useAlert();
    const queryClient = useQueryClient();

    return useMutation({
        onError: (error) => {
            showMutationAlert(error, alert);
        },
        onSuccess: (_, variables) => {
            alert.success("Custom plan measures updated.");

            queryClient.invalidateQueries([...buildingModelQueryKey, variables.buildingModelUid]);
        },
        mutationFn: async (data: UpdateCustomPlanMeasures) => 
            mutationUpdateCustomPlanMeasures(accessToken, {
                customPlanId: data.customPlanId,
                measures: data.measures,
        })
    });
}