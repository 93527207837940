import { EditIcon } from "components/atoms/Icon";
import { Paragraph } from "components/atoms/Typography";
import useMeasureSidebarState from "recoilStore/CustomPlan/useMeasureSidebarState";
import Tooltip from "components/atoms/Tooltip";
import styles from "./CustomPlanEditButton.module.scss";

interface CustomPlanEditButtonProps {
    selectedCustomPlanLength: number;
}

function CustomPlanEditButton({
    selectedCustomPlanLength,
}: CustomPlanEditButtonProps) {
    const { setState: setMeasureSidebarState } = useMeasureSidebarState();
    const isMultipleSelected = selectedCustomPlanLength > 1;
    const warningMessage =
        "You can only edit one measure at a time. Make sure to select one measure only.";

    const button = (
        <button
            className={styles.customPlanEditButton}
            type="button"
            onClick={() =>
                selectedCustomPlanLength === 1 &&
                setMeasureSidebarState({ isOpen: true })
            }
            disabled={selectedCustomPlanLength !== 1}

        >
            {selectedCustomPlanLength >= 1 && (
                <>
                    <EditIcon />
                    <Paragraph>{selectedCustomPlanLength > 1 ? "Select 1 Measure Max" : "Edit Measure" }</Paragraph>
                </>
            )}
        </button>
    );

    return isMultipleSelected ? (
        <Tooltip tooltipBody={warningMessage}>{button}</Tooltip>
    ) : (
        button
    );
}

export default CustomPlanEditButton;
