import ChartContainer from "components/molecules/ChartContainer";
import { ChartErrorState } from "components/molecules/ErrorStates";
import { useMeasureReportDecarbonizationForecastChart } from "queries/BuildingReport/Project/useMeasureReportDecarbonizationForecastChart";
import { useMemo } from "react";
import { getDisplayInfo } from "utils/formatting";
import { ResponsiveStream } from "@nivo/stream";
import { theme } from "components/molecules/Charts/ChartTheme";
import { CURRENT_YEAR } from "utils/constants";
import DecarbonizationForecastChartLegend from "./DecarbonizationForecastChartLegend";

interface DecarbonizationForecastChartProps {
    carbonReductionMeasureType: string;
    carbonReductionMeasureCategory: string;
}

function DecarbonizationForecastChart({
    carbonReductionMeasureType,
    carbonReductionMeasureCategory,
}: DecarbonizationForecastChartProps) {
    const margin = {
        top: 20,
        right: 26,
        bottom: 25,
        left: 60,
    };

    const {
        data: decarbonizationData,
        isLoading,
        isError,
    } = useMeasureReportDecarbonizationForecastChart(
        carbonReductionMeasureType
    );

    const {
        data: chartData,
        uniqueKeys,
        colors,
        maxValue,
    } = useMemo(() => {
        const chartKeyToColorMap = new Map();
        chartKeyToColorMap.set("Carbon emissions", "#E1E1E1");
        chartKeyToColorMap.set("Grid savings", "#E1E1E1");

        let maxValue = 0;

        if (!decarbonizationData)
            return {
                chartData: null,
                uniqueKeys: [],
                colors: [],
                maxValue: 0,
            };

        decarbonizationData?.annualData.forEach((datum) => {
            datum.carbonReductionMeasureTypes.forEach((measure) => {
                const {
                    carbonReductionMeasureType: measureType,
                    carbonReductionMeasureCategory: measureCategory,
                } = measure;

                let color =
                    carbonReductionMeasureCategory === measureCategory
                        ? "#AFAFAF"
                        : "#E1E1E1";

                if (measureType === carbonReductionMeasureType)
                    color = "#EB03AD";

                chartKeyToColorMap.set(measureType, color);
            });
        });

        const uniqueKeys = Array.from(chartKeyToColorMap.keys());

        const colors = Array.from(chartKeyToColorMap.values());

        const data = decarbonizationData?.annualData.map((measures) => {
            const defaultObj = Array.from(uniqueKeys).reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr]: 0,
                }),
                {}
            );

            measures.carbonReductionMeasureTypes.forEach((measure) => {
                defaultObj[measure.carbonReductionMeasureType] =
                    measure.carbonEmissionSavings;

                if (measure.carbonEmissionSavings > maxValue)
                    maxValue = measure.carbonEmissionSavings;
            });

            return {
                ...defaultObj,
                "Carbon emissions": measures.remainingCarbonEmissions,
                "Grid savings": measures.carbonEmissionSavingsFromGrid,
                year: measures.calendarYear,
            };
        });

        return { data, chartKeyToColorMap, uniqueKeys, colors, maxValue };
    }, [decarbonizationData]);

    const { unit, formatFunction } = useMemo(
        () => getDisplayInfo("carbonEmissions", maxValue),
        [chartData, maxValue]
    );

    if (isError) return <ChartErrorState />;

    return (
        <ChartContainer
            title="Decarbonization forecast"
            loading={isLoading || !chartData || !decarbonizationData}
        >
            {decarbonizationData?.percentages && (
                <DecarbonizationForecastChartLegend
                    percentages={decarbonizationData.percentages}
                    carbonReductionMeasureCategory={
                        carbonReductionMeasureCategory
                    }
                />
            )}
            <div style={{ height: "253px", width: "100%" }}>
                <ResponsiveStream
                    data={chartData!}
                    fill={[{ match: "*", id: "gradient" }]}
                    theme={theme}
                    keys={uniqueKeys}
                    valueFormat={(v) => formatFunction(v, false)}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 3,
                        tickPadding: 5,
                        legendPosition: "middle",
                        legendOffset: 36,
                        format: (v) => {
                            if (v === 0) return CURRENT_YEAR;
                            if (v === (chartData!.length || 0) - 1)
                                return "2050";
                            return "";
                        },
                    }}
                    tooltip={() => null}
                    axisLeft={{
                        tickSize: 3,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legend: unit,
                        legendOffset: margin ? margin.left * -1 + 5 : -50,
                        format: (v) => formatFunction(v, false),
                    }}
                    borderWidth={1}
                    borderColor="#fff"
                    colors={colors}
                    offsetType="none"
                    margin={margin}
                    enableStackTooltip={false}
                />
            </div>
        </ChartContainer>
    );
}

export default DecarbonizationForecastChart;