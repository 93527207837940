import { Button } from "components/atoms/Button";

interface AddEditTagsModalFooterProps {
    onSave: () => void;
    onCancel: () => void;
    onDeleteAll?: () => void;
}

function AddEditTagsModalFooter({
    onSave,
    onCancel,
    onDeleteAll,
}: AddEditTagsModalFooterProps) {
    return (
        <div className="bulk-edit-tags__footer">
            <Button type="primary" onClick={onSave}>
                Save
            </Button>
            <Button
                type="link"
                onClick={onCancel}
                style={{ color: "var(--audette-black)" }}
            >
                Cancel
            </Button>
            {onDeleteAll && (
                <Button
                    type="link"
                    onClick={onDeleteAll}
                    style={{ color: "var(--audette-content-negative)" }}
                >
                    Delete all tags
                </Button>
            )}
        </div>
    );
}

export default AddEditTagsModalFooter;  