import { request } from "graphql-request";
import { graphql } from "gql/gql";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const queryBuildingModel = (
    accessToken: string,
    data: {
        buildingModelUid: string;
    }
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            buildingModelUid: data.buildingModelUid,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data) {
            return data;  
        }
        throw new Error("Error getting building model");
    })
    .catch((error) => {
        console.error("GraphQL Error:", error);
        throw error; 
    });

const query = graphql(`
    query buildingModel($buildingModelUid: Uuid!) {
    buildingModel(buildingModelUid: $buildingModelUid) {
       carbonReductionPlans {
            name
            id
            plan {
                carbonReductionPlanUid
                carbonReductionMeasures {
                    annualEnergyConsumptionTotal
                    annualMeanCarbonEmissionSavings
                    annualMeanUtilityCostSavings
                    carbonReductionMeasureCategory
                    carbonReductionMeasureType
                    energyConsumptionSavingsElectricity
                    energyConsumptionSavingsNaturalGas
                    energyConsumptionSavingsTotal
                    incrementalCost
                    internalRateOfReturnTotal
                    lifetimeCarbonEmissionSavings
                    lifetimeCarbonTaxSavings
                    lifetimeCarbonTaxSavingsMean
                    lifetimeUtilityCostSavings
                    lifetimeUtilityCostSavingsMean
                    likeForLikeCost
                    marginalAbatementCostTotal
                    measureCost
                    measureLife
                    netPresentValueTotal
                    returnOnInvestmentTotal
                    simplePaybackYearsTotal
                    yearApplied
                }   
            }
        }
        buildingModelUid
        buildingArchetype
        buildingHeight
        buildingName
        buildingUid
        city
        country
        createdAt
        energyType
        floorsAboveGrade
        grossFloorArea
        lastUpdated
        latitude
        longitude
        modellingStatus
        postalZipCode
        propertyUid
        stateProvince
        streetAddress
        utilityDataCompleted
        yearBuiltOriginal
        coolingMultiplier
        heatingMultiplier
        lightingMultiplier
        plugLoadMultiplier
        processMultiplier
        domesticHotWaterMultiplier
        ventilationRateMultiplier
        baselineCrp { 
            carbonReductionPlanUid
            buildingModelRunUid
            scenarioType
            startYear
            endYear
            annualSummaries {
                annualSummaryUid
                year
                annualCarbonEmissions
                annualCarbonEmissionSavings
                annualCarbonOffsetCost
                annualCarbonTax 
                annualEnergyConsumptionElectricity
                annualEnergyConsumptionNaturalGas
                annualEnergyConsumptionSavingsElectricity
                annualEnergyConsumptionSavingsNaturalGas
                annualUtilityCost
                annualUtilityCostSavings
                utilityRatesElectricity
                utilityRatesNaturalGas
                utilityCarbonIntensitiesElectricity
                utilityCarbonIntensitiesNaturalGas
            }
            carbonReductionMeasures {
                carbonReductionMeasureUid
                annualEnergyConsumptionElectricity
                annualEnergyConsumptionNaturalGas
                annualEnergyConsumptionSteam
                annualEnergyConsumptionTotal
                annualMeanCarbonEmissionSavings
                annualMeanUtilityCostSavings
                carbonReductionMeasureCategory
                carbonReductionMeasureSizeUnit
                carbonReductionMeasureType
                carbonTaxDollarPerTonne
                discountRate
                energyConsumptionSavingsElectricity
                energyConsumptionSavingsNaturalGas
                energyConsumptionSavingsSteam
                energyConsumptionSavingsTotal
                incrementalCost
                internalRateOfReturnIncremental
                internalRateOfReturnTotal
                lifetimeCarbonEmissionSavings
                lifetimeCarbonTaxSavings
                lifetimeCarbonTaxSavingsMean
                lifetimeUtilityCostSavings
                lifetimeUtilityCostSavingsMean
                likeForLikeCost
                likeForLikeSize
                likeForLikeUnitCost
                marginalAbatementCost
                marginalAbatementCostIncremental
                marginalAbatementCostTotal
                measureCost
                measureLife
                measureSize
                measureUnitCost
                netPresentValue
                netPresentValueIncremental
                netPresentValueTotal
                returnOnInvestment
                returnOnInvestmentIncremental
                returnOnInvestmentTotal
                simplePaybackYearsIncremental
                simplePaybackYearsTotal
                utilityCarbonIntensitiesElectricity
                utilityCarbonIntensitiesNaturalGas
                utilityCarbonIntensitiesSteam
                utilityRatesElectricity
                utilityRatesNaturalGas
                utilityRatesSteam
                yearApplied
            }
        }
        recommendedCrp { 
            carbonReductionPlanUid
            buildingModelRunUid
            scenarioType
            startYear
            endYear
            annualSummaries {
                annualSummaryUid
                year
                annualCarbonEmissions
                annualCarbonEmissionSavings
                annualCarbonOffsetCost
                annualCarbonTax 
                annualEnergyConsumptionElectricity
                annualEnergyConsumptionNaturalGas
                annualEnergyConsumptionSavingsElectricity
                annualEnergyConsumptionSavingsNaturalGas
                annualUtilityCost
                annualUtilityCostSavings
                utilityRatesElectricity
                utilityRatesNaturalGas
                utilityCarbonIntensitiesElectricity
                utilityCarbonIntensitiesNaturalGas
            }
            carbonReductionMeasures {
                carbonReductionMeasureUid
                annualEnergyConsumptionElectricity
                annualEnergyConsumptionNaturalGas
                annualEnergyConsumptionSteam
                annualEnergyConsumptionTotal
                annualMeanCarbonEmissionSavings
                annualMeanUtilityCostSavings
                carbonReductionMeasureCategory
                carbonReductionMeasureSizeUnit
                carbonReductionMeasureType
                carbonTaxDollarPerTonne
                discountRate
                energyConsumptionSavingsElectricity
                energyConsumptionSavingsNaturalGas
                energyConsumptionSavingsSteam
                energyConsumptionSavingsTotal
                incrementalCost
                internalRateOfReturnIncremental
                internalRateOfReturnTotal
                lifetimeCarbonEmissionSavings
                lifetimeCarbonTaxSavings
                lifetimeCarbonTaxSavingsMean
                lifetimeUtilityCostSavings
                lifetimeUtilityCostSavingsMean
                likeForLikeCost
                likeForLikeSize
                likeForLikeUnitCost
                marginalAbatementCost
                marginalAbatementCostIncremental
                marginalAbatementCostTotal
                measureCost
                measureLife
                measureSize
                measureUnitCost
                netPresentValue
                netPresentValueIncremental
                netPresentValueTotal
                returnOnInvestment
                returnOnInvestmentIncremental
                returnOnInvestmentTotal
                simplePaybackYearsIncremental
                simplePaybackYearsTotal
                utilityCarbonIntensitiesElectricity
                utilityCarbonIntensitiesNaturalGas
                utilityCarbonIntensitiesSteam
                utilityRatesElectricity
                utilityRatesNaturalGas
                utilityRatesSteam
                yearApplied
            }
        }
    }
}
`);

export default queryBuildingModel;