import { InlineBoldParagraph, Paragraph } from "components/atoms/Typography";
import { formatPercentage } from "utils/formatting";

interface DecarbonizationForecastChartLegendProps {
    carbonReductionMeasureCategory: string;
    percentages: CarbonReductionMeasureDecarbonizationPercentages;
}

function DecarbonizationForecastChartLegend({
    carbonReductionMeasureCategory,
    percentages,
}: DecarbonizationForecastChartLegendProps) {
    const { currentMeasure, sameMeasureTypes, otherMeasureTypes } = percentages;

    return (
        <div className="decarbonization-forecast-chart-legend">
            <div className="decarbonization-forecast-chart-legend__item">
                <div
                    className="decarbonization-forecast-chart-legend__item__color"
                    style={{ backgroundColor: "#EB03AD" }}
                />
                <Paragraph size="small">
                    This project:{" "}
                    <InlineBoldParagraph>{`${formatPercentage(
                        currentMeasure * 100
                    )}%`}</InlineBoldParagraph>
                </Paragraph>
            </div>
            <div className="decarbonization-forecast-chart-legend__item">
                <div
                    className="decarbonization-forecast-chart-legend__item__color"
                    style={{ backgroundColor: "#AFAFAF" }}
                />
                <Paragraph size="small">
                    {`Other ${carbonReductionMeasureCategory} projects:`}{" "}
                    <InlineBoldParagraph>{`${formatPercentage(
                        sameMeasureTypes * 100
                    )}%`}</InlineBoldParagraph>
                </Paragraph>
            </div>
            <div className="decarbonization-forecast-chart-legend__item">
                <div
                    className="decarbonization-forecast-chart-legend__item__color"
                    style={{ backgroundColor: "#E1E1E1" }}
                />
                <Paragraph size="small">
                    Other projects:{" "}
                    <InlineBoldParagraph>{`${formatPercentage(
                        otherMeasureTypes * 100
                    )}%`}</InlineBoldParagraph>
                </Paragraph>
            </div>
        </div>
    );
}

export default DecarbonizationForecastChartLegend;