import { useEffect, useState } from "react";

// Components
import { ComponentErrorBoundary } from "components/molecules/ErrorStates";
import { useFormattedCarbonReductionData } from "pages/BuildingPage/Helper/BuildingPage.helper";

// hooks
import posthog from "posthog-js";
import CsvDownloadButton from "react-json-to-csv";

// styling
import "./ProjectsReport.scss";
import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";
import { useBuildingModelUid } from "hooks/useBuildingModelUid";
import errorFallbackUI from "components/molecules/ErrorFallbackUI.tsx/ErrorFallbackUI";
import { useBuildingModel } from "hooks/Building/useBuildingModel";
import { Table } from "components/molecules/Table";
import { ExportIcon } from "components/atoms/Icon";
import { Paragraph } from "components/atoms/Typography";
import { UserPermissions } from "utils/enums";
import { usePermissions } from "hooks/usePermissions";
import ProjectReportHeader from "./Cards/ProjectReportHeader";
import { FinancialImpact } from "./Cards/FinancialImpact";
import PerformanceImpact from "./Cards/PerformanceImpact";

interface ProjectsReportProps{
    carbonReductionMeasureTypeUrl?: string | null,
    carbonReductionMeasureCategoryUrl?: string | null
}


function ProjectsReport({
    carbonReductionMeasureTypeUrl,
    carbonReductionMeasureCategoryUrl
}: ProjectsReportProps) {
    const buildingModelUid = useBuildingModelUid();
    const { loadingState } = useBuildingLoadingState(buildingModelUid);

    const { data: buildingModel } = useBuildingModel(buildingModelUid);
    const { hasPermission } = usePermissions();


    useEffect(() => {
        if (loadingState.buildingReportsLoading) {
            setActiveProjectName(undefined);
        }
    }, [loadingState.buildingReportsLoading]);

    const [activeProjectName, setActiveProjectName] = 
        useState<string | undefined>(carbonReductionMeasureTypeUrl ?? undefined);
    const [activeProjectCategory, setActiveProjectCategory] =
        useState<string | undefined>(carbonReductionMeasureCategoryUrl ?? undefined);


    const handleRowClick = (row: BuildingProjectTableRow) => {
        setActiveProjectName(row.carbonReductionMeasureType);
        setActiveProjectCategory(row.carbonReductionMeasureCategory);
        posthog.capture("projects_table_row_clicked", {
            project: row.carbonReductionMeasureType,
        });
    };

    const buildingModelFetched = buildingModel?.buildingModel;

    const {
        formattedData,
        tableColumns,
        exportColumnsNames,
        dataSource
    } = useFormattedCarbonReductionData(
            (buildingModelFetched?.recommendedCrp?.carbonReductionMeasures ?? [])
                .filter((measure) => measure !== null) as CarbonReductionMeasure[],
            buildingModelFetched?.grossFloorArea ?? 1
        )
    
    // const formattedData =
    //     buildingModelFetched?.recommendedCrp?.carbonReductionMeasures?.map(
    //         (measure) => ({
    //             carbonReductionMeasureType:
    //                 measure?.carbonReductionMeasureType ?? "",
    //             carbonReductionMeasureCategory:
    //                 measure?.carbonReductionMeasureCategory ?? "",
    //             yearApplied: measure?.yearApplied ?? 0,
    //             annualEnergyConsumptionSavings:
    //                 measure?.energyConsumptionSavingsTotal ?? 0,
    //             annualCarbonEmissionSavings:
    //                 measure?.annualMeanCarbonEmissionSavings ?? 0,
    //             likeForLikeCost: measure?.likeForLikeCost ?? 0,
    //             incrementalCost: measure?.incrementalCost ?? 0,
    //             totalMeasureCost: measure?.measureCost ?? 0,
    //             totalMeasureCostIntensity:
    //                 (measure?.measureCost ?? 0) /
    //                 (buildingModelFetched.grossFloorArea ?? 1),
    //             annualMeanUtilityCostSavings:
    //                 measure?.annualMeanUtilityCostSavings ?? 0,
    //             annualCarbonTaxSavings:
    //                 measure?.lifetimeCarbonTaxSavingsMean ?? 0,
    //             netPresentValue: measure?.netPresentValueTotal ?? 0,
    //             internalRateOfReturn: measure?.internalRateOfReturnTotal ?? 0,
    //             returnOnInvestment: measure?.returnOnInvestmentTotal ?? 0,
    //             simplePayback: measure?.simplePaybackYearsTotal ?? 0,
    //             marginalAbatementCost: measure?.marginalAbatementCostTotal ?? 0,
    //         })
    //     );

    // const tableColumns = useMemo(() => {
    //     if (!formattedData) return null;
    //     if (formattedData.length === 0) return [];
    //     return tableColumnKeys.map((key) => {
    //         if (typeof formattedData[0][key] === "string") {
    //             const { formatFunction, humanReadable, tooltip } =
    //                 getDisplayInfo(key);

    //             return {
    //                 render: formatFunction,
    //                 key,
    //                 title: humanReadable,
    //                 tooltip,
    //             };
    //         }
    //         const columnValues = formattedData.map(
    //             (row) => row[key]
    //         ) as number[];

    //         const { formatFunction, humanReadable, unit, tooltip } =
    //             getDisplayInfo(key, columnValues);

    //         return {
    //             render: formatFunction,
    //             key,
    //             title: `${humanReadable} ${unit && `(${unit})`}`,
    //             tooltip,
    //         };
    //     });
    // }, [formattedData]);



    if (!dataSource || !tableColumns) return null;

    if (activeProjectName && activeProjectCategory)
        return (
            <ProjectReport
                handleBackClick={() => setActiveProjectName(undefined)}
                carbonReductionMeasureType={activeProjectName}
                carbonReductionMeasureCategory={activeProjectCategory}
            />
        );



    return (
        <div className="projects-report">
            <ComponentErrorBoundary
                fallback={errorFallbackUI("tableError")}
                originComponent="ProjectsReport"
            >
                {hasPermission(UserPermissions.UseInternalTools) && (
                    <CsvDownloadButton
                        data={dataSource}
                        filename="buildingProjectsData.csv"
                        headers={exportColumnsNames}
                        className="projects-report-export-button"
                    >
                        <div className="measures-page-flex-container">
                            <ExportIcon />
                            <Paragraph>Export</Paragraph>
                        </div>
                    </CsvDownloadButton>
                )}
                <Table
                    dataSource={dataSource}
                    columns={tableColumns}
                    className="projects-report-table"
                    pagination={false}
                    onRowClick={handleRowClick}
                />
            </ComponentErrorBoundary>
        </div>
    );
}

interface ProjectReportProps {
    handleBackClick: () => void;
    carbonReductionMeasureType: string;
    carbonReductionMeasureCategory: string;
}

export function ProjectReport({
    handleBackClick,
    carbonReductionMeasureType,
    carbonReductionMeasureCategory,
}: ProjectReportProps) {
    return (
        <div className="project-report">
            <ProjectReportHeader
                carbonReductionMeasureType={carbonReductionMeasureType}
                handleBackClick={handleBackClick}
            />
            <FinancialImpact
                carbonReductionMeasureType={carbonReductionMeasureType}
            />
            <PerformanceImpact
                carbonReductionMeasureCategory={carbonReductionMeasureCategory}
                carbonReductionMeasureType={carbonReductionMeasureType}
            />
        </div>
    );
}

export default ProjectsReport;
