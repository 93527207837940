import useFilters from "recoilStore/useFilters";
import { useMemo } from "react";
import ViewTab from "components/atoms/ViewTab";
import Filters from "components/molecules/Filters/Filters";
import Export from "components/molecules/Export/Export";
import { BuildingsTableData } from "./helpers";
import TabType from "./PortfolioPage.types";
import AddPropertiesFundsModal from "./AddPropertiesFundsModal";
import ViewButton from "./HeaderComponents/ViewButton";
import RemodelBuildingsView from "./HeaderComponents/RemodelBuildingsView";
import AddEditTags from "./HeaderComponents/AddEditTags";
import DeleteBuildings from "./HeaderComponents/DeleteBuildings";

interface ViewHeaderProps {
    numberOfBuildings?: number;
    numberOfProperties?: number;
    selectedProperties: Property[];
    setSelectedProperties: React.Dispatch<React.SetStateAction<Property[]>>;
    numberOfFunds?: number;
    handleViewChange: (view: PortfolioPageView) => void;
    currentView: PortfolioPageView;
    selectedTab: TabType;
    tabClickHandler: (tab: TabType) => void;
    buildingName?: string;
    numberOfSelectedBuildings: number;
    selectedBuildingRows: BuildingsTableData[];
    setSelectedBuildingRows: React.Dispatch<
        React.SetStateAction<BuildingsTableData[]>
    >;
    selectedTags: string[];
    selectedTagsAreMixed: boolean;
}

function ViewHeader({
    numberOfBuildings,
    numberOfProperties,
    selectedProperties,
    setSelectedProperties,
    numberOfFunds,
    handleViewChange,
    currentView,
    selectedTab,
    tabClickHandler,
    buildingName,
    numberOfSelectedBuildings,
    selectedBuildingRows,
    setSelectedBuildingRows,
    selectedTags,
    selectedTagsAreMixed,
}: ViewHeaderProps) {
    const { numberOfFilters } = useFilters("buildings");

    const clearSelectedRows = () => {
        setSelectedBuildingRows([]);
    };

    const selectedBuildingModelUids = useMemo(() => {
        if (selectedBuildingRows.length < 1) return [];

        if ((selectedBuildingRows[0] as BuildingsTableData).buildingModelUid) {
            return (selectedBuildingRows as BuildingsTableData[]).map(
                ({ buildingModelUid }) => buildingModelUid
            );
        }
        return selectedProperties.flatMap((row) =>
            row.buildingModels.map(({ buildingModelUid }) => buildingModelUid)
        );
    }, [selectedBuildingRows]);

    return (
        <div
            className="view-header"
            style={{
                borderBottom:
                    numberOfFilters > 0
                        ? "1px solid var(--audette-gray-300)"
                        : undefined,
            }}
        >
            <div className="view-header__tabs">
                <ViewTab
                    id="portfolio-buildings-tab"
                    name={
                        selectedTab === "buildings"
                            ? `Buildings (${numberOfBuildings})`
                            : "Buildings"
                    }
                    selected={selectedTab === "buildings"}
                    onClick={() => tabClickHandler("buildings")}
                />
                <ViewTab
                    id="portfolio-properties-tab"
                    name={
                        selectedTab === "properties"
                            ? `Properties (${numberOfProperties})`
                            : "Properties"
                    }
                    selected={selectedTab === "properties"}
                    onClick={() => tabClickHandler("properties")}
                />
                <ViewTab
                    id="portfolio-funds-tab"
                    name={
                        selectedTab === "funds"
                            ? `Funds (${numberOfFunds})`
                            : "Funds"
                    }
                    selected={selectedTab === "funds"}
                    onClick={() => tabClickHandler("funds")}
                />
            </div>{" "}
            {selectedTab === "buildings" && <Filters />}
            {selectedBuildingRows.length !== 0 &&
                selectedTab === "buildings" && (
                    <AddPropertiesFundsModal
                        type="properties"
                        selectedProperties={selectedProperties}
                        setSelectedProperties={setSelectedProperties}
                        selectedBuildings={selectedBuildingRows}
                        setSelectedBuildings={setSelectedBuildingRows}
                    />
                )}
            {selectedProperties.length !== 0 &&
                selectedTab === "properties" && (
                    <AddPropertiesFundsModal
                        type="funds"
                        selectedProperties={selectedProperties}
                        setSelectedProperties={setSelectedProperties}
                        selectedBuildings={selectedBuildingRows}
                        setSelectedBuildings={setSelectedBuildingRows}
                    />
                )}
            {selectedBuildingRows.length !== 0 &&
                selectedTab === "buildings" && (
                    <AddEditTags
                        selectedTags={selectedTags}
                        selectedTagsAreMixed={selectedTagsAreMixed}
                        selectedBuildingRows={
                            selectedBuildingRows as BuildingsTableData[]
                        }
                        setSelectedBuildingRows={setSelectedBuildingRows}
                    />
                )}
            {selectedTab !== "funds" && (
                <ViewButton
                    currentView={currentView}
                    handleViewChange={handleViewChange}
                />
            )}
            <Export />
            {numberOfSelectedBuildings > 0 && selectedTab === "buildings" && (
                <RemodelBuildingsView
                    selectedBuildingModelUids={selectedBuildingModelUids}
                    clearSelectedRows={clearSelectedRows}
                />
            )}
            {numberOfSelectedBuildings > 0 && selectedTab === "buildings" && (
                <DeleteBuildings
                    numberOfSelectedIds={numberOfSelectedBuildings}
                    buildingName={buildingName}
                    selectedBuildingModelUids={selectedBuildingModelUids}
                    clearSelectedRows={clearSelectedRows}
                />
            )}
        </div>
    );
}




export default ViewHeader;
