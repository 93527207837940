import { useAnnualCashFlowChart } from "queries/BuildingReport/Project/useAnnualCashFlowChart";
import { useMemo } from "react";
import AnnualCashFlowChart from "components/organisms/Charts/AnnualCashFlowChart";
import { UseQueryResult } from "react-query";
import { buildCashFlowChartKeys, buildCashFlowTableColumnKeys } from "../ProjectsReport.helper";

interface AnnualCashFlowChartProps {
    carbonReductionMeasureType: string;
    selected: "total" | "incremental";
}

function CashFlowChartWrapper({
    carbonReductionMeasureType,
    selected,
}: AnnualCashFlowChartProps) {
    const useAnnualCashFlowChartQuery = () => {
        const { data, isLoading, ...remainder } = useAnnualCashFlowChart(
            carbonReductionMeasureType
        );

        const chartData = useMemo(() => {
            if (data === undefined) return undefined;
            return data[selected];
        }, [selected, data]);

        return {
            data: chartData,
            isLoading: isLoading || (!chartData && !remainder.isError),
            ...remainder,
        };
    };

    const tableKeys = useMemo(
        () => buildCashFlowTableColumnKeys(selected),
        [selected]
    );

    return (
        <AnnualCashFlowChart
            query={useAnnualCashFlowChartQuery as () => UseQueryResult<any>}
            tableColummnKeys={tableKeys}
            barKeys={buildCashFlowChartKeys(selected)}
        />
    );
}

export default CashFlowChartWrapper;