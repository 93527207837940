import errorFallbackUI from "components/molecules/ErrorFallbackUI.tsx/ErrorFallbackUI";
import { ComponentErrorBoundary } from "components/molecules/ErrorStates";
import { Table } from "components/molecules/Table";
import { useFormattedCarbonReductionData } from "pages/BuildingPage/Helper/BuildingPage.helper";
import { usePermissions } from "hooks/usePermissions";
import { UserPermissions } from "utils/enums";
import { useEffect, useState } from "react";
import './CustomPlan.scss';
import useCustomPlanSidebarState from "recoilStore/CustomPlan/useCustomPlanSidebarState";
import CustomPlanHeader from "./CustomPlanHeader";

interface CustomPlanProps {
    currentCustomPlan: CurrentCustomPlan;
    grossFloorArea: number;
    currentCarbonReductionPlanUid: string;
}

function CustomPlan ({ 
    currentCustomPlan,
    grossFloorArea,
    currentCarbonReductionPlanUid
}: CustomPlanProps) {
    const [selectedCustomPlan, setSelectedCustomPlan] = useState<CustomPlanDataSource[]>([]);

    const { hasPermission } = usePermissions();
    const { setState: setCustomPlanSidebarState } = useCustomPlanSidebarState();

    useEffect(() => {
        setCustomPlanSidebarState({ selectedRows: selectedCustomPlan });
    }, [selectedCustomPlan]);
    
    const {
        formattedData,
        tableColumns,
        exportColumnsNames,
        dataSource
    } = useFormattedCarbonReductionData(
            currentCustomPlan.carbonReductionMeasures,
            grossFloorArea,
            currentCustomPlan.carbonReductionPlanUid
        )
        
    return(
        <div className="custom-plan-report">
        <ComponentErrorBoundary
            fallback={errorFallbackUI("tableError")}
            originComponent="ProjectsReport"
        >
            {hasPermission(UserPermissions.UseInternalTools) && (
                <CustomPlanHeader 
                    formattedData={formattedData}
                    exportColumnsNames={exportColumnsNames}
                    selectedCustomPlan={selectedCustomPlan}
                    setSelectedRows={setSelectedCustomPlan}
                    currentCarbonReductionPlanUid={currentCarbonReductionPlanUid}
                />

            )}
            <Table
                dataSource={dataSource}
                columns={tableColumns}
                className="measures-projects-report-table"
                pagination={false}
                selectedRows={selectedCustomPlan}
                setSelectedRows={setSelectedCustomPlan}
                // onRowClick={handleRowClick}
            />
        </ComponentErrorBoundary>
    </div>
    )
}

export default CustomPlan;
