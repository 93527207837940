import { useTotalCostAndSavingsCard } from "queries/BuildingReport/Project/useTotalCostAndSavingsCard";
import { useMemo } from "react";
import { displayValueWithUnit, getDisplayInfo } from "utils/formatting";
import { CardErrorState } from "components/molecules/ErrorStates";
import { Header, Paragraph } from "components/atoms/Typography";
import { buildTotalCostAndSavingsCardDataStruct, LargeCardLoading } from "../ProjectsReport.helper";
import ProjectCardSection from "./ProjectCardSection";

interface TotalCostAndSavingsCardProps {
    selectedView: "incremental" | "total";
    carbonReductionMeasureType: string;
}

function TotalCostAndSavingsCard({
    selectedView,
    carbonReductionMeasureType,
}: TotalCostAndSavingsCardProps) {
    const {
        data: cardData,
        isLoading,
        isError,
    } = useTotalCostAndSavingsCard(carbonReductionMeasureType);

    const data = useMemo(() => {
        if (!cardData) return null;
        return buildTotalCostAndSavingsCardDataStruct(cardData[selectedView]);
    }, [selectedView, cardData]);

    const {
        humanReadable: discountRateTitle,
        unit: discountRateUnit,
        formatFunction: discountFormatFn,
    } = getDisplayInfo("discountRate");

    if (isError) return <CardErrorState />;
    if (isLoading || !data) return <LargeCardLoading />;

    return (
        <div className="large-card">
            <Header size="small">Costs & Savings</Header>
            {data.map((section) => (
                <ProjectCardSection key={section.title.key} data={section} />
            ))}
            <div className="discount-rate">
                <Paragraph size="small">{discountRateTitle}</Paragraph>
                <Paragraph size="small">
                    {displayValueWithUnit(
                        cardData![selectedView].discountRate,
                        discountRateUnit,
                        (v) => discountFormatFn(Number(v) * 100)
                    )}
                </Paragraph>
            </div>
        </div>
    );
}

export default TotalCostAndSavingsCard;