import { displayValueWithUnit, formatPercentage, getDisplayInfo, getTextColorByValue } from "utils/formatting";
import { Header, Paragraph } from "components/atoms/Typography";
import { capitalizeFirstLetter } from "utils/helpers";
import { CardSection } from "../ProjectsReport.helper";

interface ProjectCardSectionProps {
    data: CardSection<string>;
}

function ProjectCardSection({ data }: ProjectCardSectionProps) {
    const {
        humanReadable: title,
        formatFunction: formatTitle,
        unit,
    } = getDisplayInfo(data.title.key, data.title.value);

    return (
        <div className="project-card-section">
            <div className="project-card-section__title">
                {data.title.icon}
                <Header size="x-small">{title}</Header>
                <Header
                    size="x-small"
                    style={{
                        color: getTextColorByValue(
                            data.title.value,
                            title.includes("cost")
                        ),
                    }}
                >
                    {displayValueWithUnit(data.title.value, unit, (v) =>
                        formatTitle(v)
                    )}
                </Header>
            </div>
            <ul className="project-card-section__body">
                {data.body.map(({ key, value, percentage }) => {
                    const { humanReadable, formatFunction, unit } =
                        getDisplayInfo(key, value);

                    const displayValue = humanReadable
                        .toLowerCase()
                        .replace("cost", "")
                        .trim();

                    return (
                        <li key={key}>
                            <div>
                                <Paragraph size="small">
                                    {capitalizeFirstLetter(displayValue)}
                                </Paragraph>
                                <Paragraph size="small">
                                    {displayValueWithUnit(value, unit, (v) =>
                                        formatFunction(v)
                                    )}
                                </Paragraph>
                                {percentage !== undefined && (
                                    <Paragraph size="small">
                                        {formatPercentage(percentage)}%
                                    </Paragraph>
                                )}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default ProjectCardSection;