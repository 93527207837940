import { MapIcon, TableIcon } from "components/atoms/Icon";
import { Paragraph } from "components/atoms/Typography";

interface ViewButtonProps {
    currentView: PortfolioPageView;
    handleViewChange: (view: PortfolioPageView) => void;
}

function ViewButton({ currentView, handleViewChange }: ViewButtonProps) {
    return currentView === "table" ? (
        <button
            id="buildings-view-map-button"
            type="button"
            onClick={() => handleViewChange("map")}
        >
            <MapIcon />
            <Paragraph>Map</Paragraph>
        </button>
    ) : (
        <button
            id="buildings-view-table-button"
            type="button"
            onClick={() => handleViewChange("table")}
        >
            <TableIcon />
            <Paragraph>Table</Paragraph>
        </button>
    );
}

export default ViewButton;