import { useMemo } from "react";
import { getDisplayInfo } from "utils/formatting";

export const tableColumnKeys: (keyof BuildingProjectTableRow)[] = [
    "carbonReductionMeasureType",
    "carbonReductionMeasureCategory",
    "yearApplied",
    "annualEnergyConsumptionSavings",
    "annualCarbonEmissionSavings",
    "likeForLikeCost",
    "incrementalCost",
    "totalMeasureCost",
    "totalMeasureCostIntensity",
    "annualCarbonTaxSavings",
    "netPresentValue",
    "internalRateOfReturn",
    "returnOnInvestment",
    "simplePayback",
    "marginalAbatementCost",
];

export function useFormattedCarbonReductionData(
    measures: CarbonReductionMeasure[], 
    grossFloorArea?: number,
    carbonReductionPlanUid?: string
) {
    const formattedData = measures.map(
        (measure) => ({
            carbonReductionMeasureType:
                measure?.carbonReductionMeasureType ?? "",
            carbonReductionMeasureCategory:
                measure?.carbonReductionMeasureCategory ?? "",
            yearApplied: measure?.yearApplied ?? 0,
            annualEnergyConsumptionSavings:
                measure?.energyConsumptionSavingsTotal ?? 0,
            annualCarbonEmissionSavings:
                measure?.annualMeanCarbonEmissionSavings ?? 0,
            likeForLikeCost: measure?.likeForLikeCost ?? 0,
            incrementalCost: measure?.incrementalCost ?? 0,
            totalMeasureCost: measure?.measureCost ?? 0,
            totalMeasureCostIntensity:
                (measure?.measureCost ?? 0) /
                (grossFloorArea ?? 1),
            annualMeanUtilityCostSavings:
                measure?.annualMeanUtilityCostSavings ?? 0,
            annualCarbonTaxSavings:
                measure?.lifetimeCarbonTaxSavingsMean ?? 0,
            netPresentValue: measure?.netPresentValueTotal ?? 0,
            internalRateOfReturn: measure?.internalRateOfReturnTotal ?? 0,
            returnOnInvestment: measure?.returnOnInvestmentTotal ?? 0,
            simplePayback: measure?.simplePaybackYearsTotal ?? 0,
            marginalAbatementCost: measure?.marginalAbatementCostTotal ?? 0,
            carbonReductionPlanUid: carbonReductionPlanUid ?? "",
        })
    );

    const tableColumns = useMemo(() => {
        if (!formattedData) return [];
        if (formattedData.length === 0) return [];
        return tableColumnKeys.map((key) => {
            if (typeof formattedData[0][key] === "string") {
                const { formatFunction, humanReadable, tooltip } =
                    getDisplayInfo(key);

                return {
                    render: formatFunction,
                    key,
                    title: humanReadable,
                    tooltip,
                };
            }
            const columnValues = formattedData.map(
                (row) => row[key]
            ) as number[];

            const { formatFunction, humanReadable, unit, tooltip } =
                getDisplayInfo(key, columnValues);

            return {
                render: formatFunction,
                key,
                title: `${humanReadable} ${unit && `(${unit})`}`,
                tooltip,
            };
        });
    }, [formattedData]);

    const exportColumnsNames =
        tableColumns?.map((column) => column.title) ?? [];

    const dataSource = useMemo(() => {
        if (!formattedData) return [];

        const filteredData = formattedData.filter(
            (row) =>
                row.carbonReductionMeasureType !== "No Action" &&
                row.carbonReductionMeasureType !== "Base building state" &&
                row.carbonReductionMeasureType !== "unmapped"
        );

        return filteredData.map((row, index) => ({
            ...row,
            key: `${
                row.carbonReductionMeasureType
                    ? row.carbonReductionMeasureType
                    : "defaultKey"
            }-${index}`,
        }));
    }, [formattedData]);

    return {
        formattedData,
        tableColumns,
        exportColumnsNames,
        dataSource
    };
}