export function cellIsValid<T>(value: T) {
    return value !== undefined && value !== null;
}

export const getPaginationPageOptions = (rowCount: number) => {
    const options = PAGINATION_OPTIONS;
    return [{ label: "Show all", value: rowCount }, ...options];
};

export function calculateAlignmentValue<T>(value: T) {
    if (typeof value === "number") return "right";
    return "left";
}



export const PAGINATION_OPTIONS = [100, 200, 500];

export const splitTitleIntoNameAndUnit = (
    title: string
): {
    name: string;
    unit: string | null;
} => {
    const name = title.replace(/\s*\([^)]*\)\s*/, "");
    const unitMatchArray = title.match(/\(.*?\)/);
    const unit = unitMatchArray ? unitMatchArray[0] : null;

    return { name, unit };
};
