let BACKEND_DOMAIN = "";
let VALIDATION_SERVICE_DOMAIN = "";
if (process.env.REACT_APP_ENV === "mock") {
    BACKEND_DOMAIN =
        "https://2adcc1bc-f376-4358-a3cc-e470a014a966.mock.pstmn.io";
    VALIDATION_SERVICE_DOMAIN =
        "https://validation-service-noodmd76fa-nn.a.run.app";
} else if (process.env.REACT_APP_DEPLOY_ENV === "local") {
    BACKEND_DOMAIN = "http://localhost:5006";
    VALIDATION_SERVICE_DOMAIN = "http://localhost:5019";
} else if (process.env.REACT_APP_DEPLOY_ENV === "dockerlocal") {
    BACKEND_DOMAIN = "http://127.0.0.1:5006";
    VALIDATION_SERVICE_DOMAIN = "http://127.0.0.1:5006";    
} else if (process.env.REACT_APP_DEPLOY_ENV === "dev") {
    BACKEND_DOMAIN = "https://cre-api-vm6kpwyzpa-nn.a.run.app";
    VALIDATION_SERVICE_DOMAIN =
        "https://validation-service-vm6kpwyzpa-nn.a.run.app";
} else if (process.env.REACT_APP_DEPLOY_ENV === "staging") {
    BACKEND_DOMAIN = "https://cre-api-b7gopv7owq-nn.a.run.app";
    VALIDATION_SERVICE_DOMAIN =
        "https://validation-service-b7gopv7owq-nn.a.run.app";
} else if (process.env.REACT_APP_DEPLOY_ENV === "prod") {
    BACKEND_DOMAIN = "https://cre-api-d2v6czyzya-nn.a.run.app";
    VALIDATION_SERVICE_DOMAIN =
        "https://validation-service-d2v6czyzya-nn.a.run.app";
}

export { BACKEND_DOMAIN, VALIDATION_SERVICE_DOMAIN };

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN || "REACT_APP_MAPBOX_TOKEN_IS_MISSING";

export const CURRENT_YEAR = new Date().getFullYear();
export const END_YEAR = 2050;

interface BuildingTypeOption {
    key: BuildingType;
    label: string;
}
export const buildingTypeOptions: BuildingTypeOption[] = [
    {
        key: "enclosed_mall",
        label: "Enclosed mall",
    },
    {
        key: "full_service_restaurant",
        label: "Full Service Restaurant",
    },
    {
        key: "food_retail_grocery",
        label: "Grocery Store",
    },
    {
        key: "hotel",
        label: "Hotel",
    },
    {
        key: "longterm_care",
        label: "Longterm Care",
    },
    {
        key: "medical_office",
        label: "Medical Office",
    },
    {
        key: "multi_unit_residential",
        label: "Multi-Unit Residential",
    },
    {
        key: "office",
        label: "Office",
    },
    {
        key: "quick_service_restaurant",
        label: "Quick Service Restaurant",
    },
    {
        key: "non_food_retail_closed",
        label: "Retail Store",
    },
    {
        key: "school",
        label: "School",
    },
    {
        key: "townhomes",
        label: "Townhomes",
    },
    {
        key: "non_food_retail_open",
        label: "Strip Mall",
    },
    {
        key: "warehouse",
        label: "Warehouse",
    },
];

interface EnergyTypeOption {
    key: EnergyTypeEnum;
    label: string;
}
export const energyTypeOptions: EnergyTypeOption[] = [
    {
        key: "electric",
        label: "Electric",
    },
    {
        key: "electric_inferred",
        label: "Electric",
    },
    {
        key: "mixed",
        label: "Mixed",
    },
    {
        key: "mixed_inferred",
        label: "Mixed",
    },
];

interface EnergyTypeInputOptions {
    key: string;
    label: string;
}
export const energyTypeInputOptions: EnergyTypeInputOptions[] = [
    {
        key: "mixed",
        label: "Yes",
    },
    {
        key: "electric",
        label: "No",
    },
    {
        key: "mixed_inferred",
        label: "I don't know",
    },
];

export const uniqueBuildingTypes = buildingTypeOptions.map(({ key }) => key);

export const uniqueEnergyTypes = energyTypeInputOptions
    .filter((item) => !item.key.includes("inferred"))
    .map(({ key }) => key);

interface HeatingTypeOption {
    key: HeatingType;
    label: string;
}
const heatingTypeOptions: HeatingTypeOption[] = [
    {
        key: "natural_gas_boiler",
        label: "Natural Gas Boiler",
    },
    {
        key: "natural_gas_rooftop_unit",
        label: "Natural Gas RTU",
    },
];

const buildingVintageRange = {
    min: 1700,
    max: 2030,
};

const buildingGrossFloorAreaRange = {
    min: 0,
    max: 5000000,
};

export const buildingFiltersOptions = {
    buildingVintageRange,
    buildingGrossFloorAreaRange,
    heatingTypeOptions,
    buildingTypeOptions,
};

export const BUILDING_COLOURS = {
    low: "#00A081",
    mid: "#F7931E",
    high: "#F94646",
};

export const ERROR_CODES: { [key: string]: ErrorType } = {
    NO_AUTHN: "no-authn",
    NO_AUTHZ: "no-authz",
    API_ERROR: "api-error",
};

export const DEFAULT_BUILDING_AGGREGATION = {
    annualCarbonEmissionIntensityCurrent: 0,
    annualCarbonEmissionsCurrent: 0,
    annualCarbonEmissionReduction2050: 0,
    annualCarbonEmissionSavings2050: 0,
    annualUtilityCostReduction2050: 0,
    annualUtilityCostSavings2050: 0,
    debtToValueRatio: 0,
    endUse: [],
    frequencyDistributions: {
        annualCarbonEmissionIntensityCurrent: {
            bucketLimits: [],
            counts: [],
        },
        grossFloorArea: {
            bucketLimits: [],
            counts: [],
        },
    },
    statisticsByBuildingArchetype: [],
    totalCarbonTaxNoActions: 0,
    totalCarbonTaxReduction: 0,
    totalCarbonTaxSavings: 0,
    totalIncrementalCost: 0,
    totalLikeForLikeCost: 0,
    totalMeasureCost: 0,
    totalUtilityCostReduction: 0,
    totalUtilityCostSavings: 0,
};

export const buildingPropertyToConstraints: {
    [key: string]: {
        min?: number;
        max?: number;
        isInteger?: boolean;
    };
} = {
    availableRoofAreaRatio: { min: 0, max: 1 },
    buildingHeight: { min: 0 },
    floorsAboveGrade: { min: 0, isInteger: true },
    grossFloorArea: { min: 0 },
    ledInstalledRatio: { min: 0, max: 1 },
    yearBuiltOriginal: {
        max: new Date().getFullYear(),
        isInteger: true,
    },
};

export const PER_YEAR = "/yr";

export const QUERY_STALE_TIME = 60 * (60 * 1000); // 1 hour

export const SQUARE_METERS_TO_SQUARE_FEET_MULTIPLIER = 10.7639;

export const leftAlignedKeys = [
    "accountNumber",
    "buildingArchetype",
    "buildingName",
    "carbonReductionMeasureCategory",
    "carbonReductionMeasureType",
    "dataCoverage",
    "dataCoverages",
    "currency",
    "endDate",
    "energyConsumptionUnit",
    "energyType",
    "fundName",
    "insertTime",
    "propertyArchetype",
    "propertyName",
    "startDate",
    "streetAddress",
    "tags",
    "utilityProvider",
    "email",
    "role",
    "status",
    "dateJoined"
];

export const provinceStateOptions = {
    US: [
      { value: 'AL', label: 'Alabama' },
      { value: 'AK', label: 'Alaska' },
      { value: 'AZ', label: 'Arizona' },
      { value: 'AR', label: 'Arkansas' },
      { value: 'CA', label: 'California' },
      { value: 'CO', label: 'Colorado' },
      { value: 'CT', label: 'Connecticut' },
      { value: 'DE', label: 'Delaware' },
      { value: 'FL', label: 'Florida' },
      { value: 'GA', label: 'Georgia' },
      { value: 'HI', label: 'Hawaii' },
      { value: 'ID', label: 'Idaho' },
      { value: 'IL', label: 'Illinois' },
      { value: 'IN', label: 'Indiana' },
      { value: 'IA', label: 'Iowa' },
      { value: 'KS', label: 'Kansas' },
      { value: 'KY', label: 'Kentucky' },
      { value: 'LA', label: 'Louisiana' },
      { value: 'ME', label: 'Maine' },
      { value: 'MD', label: 'Maryland' },
      { value: 'MA', label: 'Massachusetts' },
      { value: 'MI', label: 'Michigan' },
      { value: 'MN', label: 'Minnesota' },
      { value: 'MS', label: 'Mississippi' },
      { value: 'MO', label: 'Missouri' },
      { value: 'MT', label: 'Montana' },
      { value: 'NE', label: 'Nebraska' },
      { value: 'NV', label: 'Nevada' },
      { value: 'NH', label: 'New Hampshire' },
      { value: 'NJ', label: 'New Jersey' },
      { value: 'NM', label: 'New Mexico' },
      { value: 'NY', label: 'New York' },
      { value: 'NC', label: 'North Carolina' },
      { value: 'ND', label: 'North Dakota' },
      { value: 'OH', label: 'Ohio' },
      { value: 'OK', label: 'Oklahoma' },
      { value: 'OR', label: 'Oregon' },
      { value: 'PA', label: 'Pennsylvania' },
      { value: 'RI', label: 'Rhode Island' },
      { value: 'SC', label: 'South Carolina' },
      { value: 'SD', label: 'South Dakota' },
      { value: 'TN', label: 'Tennessee' },
      { value: 'TX', label: 'Texas' },
      { value: 'UT', label: 'Utah' },
      { value: 'VT', label: 'Vermont' },
      { value: 'VA', label: 'Virginia' },
      { value: 'WA', label: 'Washington' },
      { value: 'WV', label: 'West Virginia' },
      { value: 'WI', label: 'Wisconsin' },
      { value: 'WY', label: 'Wyoming' },
      { value: 'DC', label: 'District of Columbia' }
    ],
    Canada: [
      { value: 'AB', label: 'Alberta' },
      { value: 'BC', label: 'British Columbia' },
      { value: 'MB', label: 'Manitoba' },
      { value: 'NB', label: 'New Brunswick' },
      { value: 'NL', label: 'Newfoundland and Labrador' },
      { value: 'NS', label: 'Nova Scotia' },
      { value: 'NT', label: 'Northwest Territories' },
      { value: 'NU', label: 'Nunavut' },
      { value: 'ON', label: 'Ontario' },
      { value: 'PE', label: 'Prince Edward Island' },
      { value: 'QC', label: 'Quebec' },
      { value: 'SK', label: 'Saskatchewan' },
      { value: 'YT', label: 'Yukon' }
    ]
  };

  export const uniqueStateProvince = [
    ...provinceStateOptions.US.map(state => state.value),
    ...provinceStateOptions.Canada.map(province => province.value)
];