import {
    BooleanFilterCondition,
    BooleanFilterField,
    DataCoverageEnum,
    EnergyTypeEnum,
    NumericFilterCondition,
    NumericFilterField,
    ReportFilter,
    StringFilterCondition,
    StringFilterField,
} from "gql/graphql";
import { buildingTypeOptions } from "./constants";

export const findBuildingArchetypeDisplayLabel = (
    buildingType: string | undefined
) => buildingTypeOptions.find((option) => option.key === buildingType)?.label;

const buildApiRequestHeaders = (accessToken: string) => ({
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
});

const buildPostmanRequestHeaders = () => {
    const postmanSecret = process.env.REACT_APP_POSTMAN_SECRET;
    if (!postmanSecret)
        throw new Error(
            "Please define REACT_APP_POSTMAN_SECRET in the env file"
        );
    return {
        "x-api-key": postmanSecret,
        "Content-Type": "application/json",
    };
};

export const buildRequestHeaders = (accessToken: string) => {
    if (process.env.REACT_APP_ENV === "mock") {
        return buildPostmanRequestHeaders();
    }
    return buildApiRequestHeaders(accessToken);
};

export const buildBuildingModelRequestVariables = (
    buildingModelUid: string,
    carbonReductionMeasureType?: string
) => {
    if (process.env.REACT_APP_ENV === "mock") {
        const variables: any = {
            buildingModelUid: "6865c439-e03a-45f2-a926-20f201219c0b",
        };
        if (carbonReductionMeasureType)
            variables.carbonReductionMeasureType = "carbonReductionMeasureType";
        return variables;
    }
    const variables: any = { buildingModelUid };
    if (carbonReductionMeasureType)
        variables.carbonReductionMeasureType = carbonReductionMeasureType;
    return variables;
};

export const buildFootprintRequestVariables = (address: Address) => {
    if (process.env.REACT_APP_ENV === "mock")
        return {
            address: {
                streetAddress: "300 S Monroe St",
                city: "Denver",
                stateProvince: "Colorado",
                postalZipCode: "80209",
                country: "USA",
            },
        };
    return { address };
};

export const buildReportsRequestVariables = (
    filters: Filters
): { reportFilter: ReportFilter } => {
    if (process.env.REACT_APP_ENV === "mock")
        return {
            reportFilter: {
                boolean_filters: [],
                data_coverage_filters: [],
                numeric_filters: [],
                string_filters: [],
            },
        };

    return {
        reportFilter: {
            boolean_filters: buildBooleanFilterForAPI(filters.booleanFilters),
            data_coverage_filters:
                filters.dataCoverageFilter as DataCoverageEnum[],
            numeric_filters: buildNumericFiltersForAPI(filters.numericFilters),
            string_filters: buildStringFiltersForAPI(filters.stringFilters),
        },
    };
};

const buildBooleanFilterForAPI = (booleanFilters: BooleanFilter[]) =>
    booleanFilters.map(({ field, condition }) => ({
        field: field as BooleanFilterField,
        condition: condition as BooleanFilterCondition,
    }));

const buildStringFiltersForAPI = (stringFilters: StringFilter[]) =>
    stringFilters.map(({ field, condition, value }) => ({
        field: field as StringFilterField,
        condition: condition as StringFilterCondition,
        value,
    }));

const buildNumericFiltersForAPI = (numericFilters: NumericFilter[]) =>
    numericFilters.flatMap(({ field, condition, value }) => {
        if (condition === "between") {
            return [
                {
                    field: field as NumericFilterField,
                    condition: NumericFilterCondition.LessThanEqual,
                    value: value[0],
                },
                {
                    field: field as NumericFilterField,
                    condition: NumericFilterCondition.GreaterThanEqual,
                    value: value[1],
                },
            ];
        }
        return {
            field: field as NumericFilterField,
            condition: condition as NumericFilterCondition,
            value: value[0],
        };
    });

export const capitalizeFirstLetter = (word: string) =>
    word.charAt(0).toUpperCase() + word.slice(1);

const DataCoverageWarnings = {
    equipmentWarning: false,
    energyWarning: false,
    hasNaturalGasWarning: false,
};

export const getDataCoverageWarnings = (dataCoverage: DataCoverage) => {
    const electricEnergyTypes = ["electric", "electric_inferred"];
    const mixedEnergyTypes = ["mixed", "mixed_inferred"];

    // Electric Energy Type
    if (electricEnergyTypes.includes(dataCoverage.energyType)) {
        if (
            dataCoverage.energyTypeFromEquipmentData !== EnergyTypeEnum.Mixed &&
            dataCoverage.energyTypeFromUtilityData !== EnergyTypeEnum.Mixed
        )
            return {
                ...DataCoverageWarnings,
            };
        if (
            dataCoverage.energyTypeFromEquipmentData === EnergyTypeEnum.Mixed &&
            dataCoverage.energyTypeFromUtilityData === EnergyTypeEnum.Mixed
        )
            return {
                ...DataCoverageWarnings,
                energyWarning: true,
                equipmentWarning: true,
                hasNaturalGasWarning: true,
            };
        if (
            dataCoverage.energyTypeFromEquipmentData === EnergyTypeEnum.Mixed &&
            dataCoverage.energyTypeFromUtilityData !== EnergyTypeEnum.Mixed
        )
            return {
                ...DataCoverageWarnings,
                equipmentWarning: true,
                hasNaturalGasWarning: true,
            };
        if (
            dataCoverage.energyTypeFromEquipmentData !== EnergyTypeEnum.Mixed &&
            dataCoverage.energyTypeFromUtilityData === EnergyTypeEnum.Mixed
        )
            return {
                ...DataCoverageWarnings,
                energyWarning: true,
            };
    }

    // Mixed Energy Type
    if (mixedEnergyTypes.includes(dataCoverage.energyType)) {
        if (
            dataCoverage.energyTypeFromEquipmentData === EnergyTypeEnum.Mixed &&
            dataCoverage.energyTypeFromUtilityData !== EnergyTypeEnum.Electric
        )
            return {
                ...DataCoverageWarnings,
            };
        if (
            dataCoverage.energyTypeFromEquipmentData === EnergyTypeEnum.Mixed &&
            dataCoverage.energyTypeFromUtilityData === EnergyTypeEnum.Electric
        )
            return {
                ...DataCoverageWarnings,
                energyWarning: true,
            };
        if (
            dataCoverage.energyTypeFromEquipmentData ===
                EnergyTypeEnum.Electric &&
            dataCoverage.energyTypeFromUtilityData !== EnergyTypeEnum.Electric
        )
            return {
                ...DataCoverageWarnings,
                equipmentWarning: true,
            };
        if (
            dataCoverage.energyTypeFromEquipmentData === null &&
            dataCoverage.energyTypeFromUtilityData === null
        )
            return {
                ...DataCoverageWarnings,
            };
        if (
            dataCoverage.energyTypeFromEquipmentData === null &&
            dataCoverage.energyTypeFromUtilityData === EnergyTypeEnum.Electric
        )
            return {
                ...DataCoverageWarnings,
                energyWarning: true,
            };
        if (
            dataCoverage.energyTypeFromEquipmentData ===
                EnergyTypeEnum.Electric &&
            dataCoverage.energyTypeFromUtilityData === EnergyTypeEnum.Electric
        )
            return {
                ...DataCoverageWarnings,
                equipmentWarning: true,
                energyWarning: true,
            };
    }

    return DataCoverageWarnings as DataCoverageWarningsList;
};

export const showMutationAlert = (errors: any, alert: any) => {
    try {
        if (typeof errors === 'string') {
            alert.error(errors);
            return;
        }
        
        const errorsObj = errors.response.errors;

        if (errorsObj.length === 0) throw new Error("Errors array is empty");

        const error = errorsObj[0];

        if (!isError(error)) throw new Error("Error not handled");

        alert.error(error.description);
    } catch {
        alert.error("An error occurred. Please contact Audette.");
    }
};

const isError = (obj: any): obj is Error =>
    obj &&
    typeof obj.errorCode === "string" &&
    typeof obj.description === "string";


export const filterTableData = (
    tableData: any[],
    clientFilters: Filters,
    numberOfFilters: number
) =>
    tableData.filter((datum) => {
        if (numberOfFilters === 0) return true;

        const {
            stringFilters,
            numericFilters,
            dataCoverageFilter,
            dateFilters,
        } = clientFilters;

        const dataCoverageFiltered =
            dataCoverageFilter.length === 0
                ? true
                : dataCoverageFilter.includes(datum.dataCoverage.dataCoverage);

        const stringFiltered = stringFilters.reduce((prev, filter) => {
            const { value, field } = filter;
            if (value.length > 1 || field === "tag")
                return filterMultiSelect(datum, filter) && prev;

            return filterString(datum, filter) && prev;
        }, true);

        const numericFiltered = numericFilters.reduce(
            (prev, filter) => filterNumeric(datum, filter) && prev,
            true
        );

        const dateFiltered = dateFilters.reduce(
            (prev, filter) => filterDate(datum, filter) && prev,
            true
        );

        return (
            numericFiltered &&
            stringFiltered &&
            dataCoverageFiltered &&
            dateFiltered
        );
    });

const filterMultiSelect = (
    datum: BuildingModelsTableDatum,
    filter: StringFilter
): boolean => {
    const { key, condition, value } = filter;
    const buildingModelValue = datum[key as keyof BuildingModelsTableDatum];

    if (buildingModelValue === null) return false;

    if (Array.isArray(buildingModelValue)) {
        if (condition === "equal")
            return buildingModelValue.reduce(
                (prev, curr) => value.includes(curr) || prev,
                false
            );
        if (condition === "not_equal")
            return buildingModelValue.reduce(
                (prev, curr) => !value.includes(curr) || prev,
                false
            );
    }

    if (condition === "equal")
        return value.includes(buildingModelValue as string);
    if (condition === "not_equal")
        return !value.includes(buildingModelValue as string);
    return true;
};

export const filterString = (
    datum: BuildingModelsTableDatum,
    filter: StringFilter
): boolean => {
    const { key, condition, value } = filter;

    const filterValue = value[0].toLowerCase();

    let datumValue;

    if (
        key === "streetAddress" ||
        key === "stateProvince" ||
        key === "city" ||
        key === "country"
    )
        datumValue = datum.location[key].toLowerCase();
    else {
        try {
            datumValue = (
                datum[key as keyof BuildingModelsTableDatum] as string
            ).toLowerCase();
        } catch (error) {
            datumValue = "";
        }
    }

    if (condition === "contains") return datumValue.includes(filterValue);

    if (condition === "does_not_contain")
        return !datumValue.includes(filterValue);

    if (condition === "equal") return datumValue === filterValue;
    if (condition === "not_equal") return datumValue !== filterValue;
    if (condition === "starts_with") return datumValue.startsWith(filterValue);
    return true;
};

const filterNumeric = (
    datum: BuildingModelsTableDatum,
    filter: NumericFilter
): boolean => {
    const { key, condition, value } = filter;
    const datumValue = datum[key as keyof BuildingModelsTableDatum];

    if (datumValue === null && condition === "not_equal") return true;
    if (typeof datumValue !== "number") return false;

    if (condition === "between" && value.length === 2)
        return datumValue >= Number(value[0]) && datumValue <= Number(value[1]);

    const filterValue = Number(value[0]);

    if (condition === "equal") return datumValue === filterValue;

    if (condition === "not_equal") return datumValue !== filterValue;
    if (condition === "less_than_equal") return datumValue <= filterValue;
    if (condition === "greater_than_equal") return datumValue >= filterValue;

    return true;
};

const filterDate = (
    datum: BuildingModelsTableDatum,
    filter: DateFilter
): boolean => {
    const { key, condition, value } = filter;

    const datumValue = datum[key as keyof BuildingModelsTableDatum];

    if (datumValue === null && condition === "not_equal") return true;

    const datumDateValue = new Date(datumValue as (typeof value)[0]);

    const datumYear = datumDateValue.getFullYear();
    const datumMonth = datumDateValue.getMonth();
    const datumDay = datumDateValue.getDate();

    const filterDateValue = new Date(value[0]);

    const filterYear = filterDateValue.getFullYear();
    const filterMonth = filterDateValue.getMonth();
    const filterDay = filterDateValue.getDate();

    if (condition === "between" && value.length === 2) {
        const filterDateEndValue = new Date(value[1]);

        return (
            datumDateValue >= filterDateValue &&
            datumDateValue <= filterDateEndValue
        );
    }

    if (condition === "equal")
        return (
            datumYear === filterYear &&
            datumMonth === filterMonth &&
            datumDay === filterDay
        );
    if (condition === "not_equal")
        return (
            datumYear !== filterYear ||
            datumMonth !== filterMonth ||
            datumDay !== filterDay
        );
    if (condition === "before") return datumDateValue <= filterDateValue;
    if (condition === "after") return datumDateValue >= filterDateValue;

    return true;
};


export const provinceStateMapping: { [key: string]: string } = {
    // Canadian Provinces
    'alberta': 'AB',
    'british columbia': 'BC',
    'manitoba': 'MB',
    'new brunswick': 'NB',
    'newfoundland and labrador': 'NL',
    'nova scotia': 'NS',
    'ontario': 'ON',
    'prince edward island': 'PE',
    'quebec': 'QC',
    'saskatchewan': 'SK',
    'northwest territories': 'NT',
    'nunavut': 'NU',
    'yukon': 'YT',

    // US States
    'alabama': 'AL',
    'alaska': 'AK',
    'arizona': 'AZ',
    'arkansas': 'AR',
    'california': 'CA',
    'colorado': 'CO',
    'connecticut': 'CT',
    'delaware': 'DE',
    'florida': 'FL',
    'georgia': 'GA',
    'hawaii': 'HI',
    'idaho': 'ID',
    'illinois': 'IL',
    'indiana': 'IN',
    'iowa': 'IA',
    'kansas': 'KS',
    'kentucky': 'KY',
    'louisiana': 'LA',
    'maine': 'ME',
    'maryland': 'MD',
    'massachusetts': 'MA',
    'michigan': 'MI',
    'minnesota': 'MN',
    'mississippi': 'MS',
    'missouri': 'MO',
    'montana': 'MT',
    'nebraska': 'NE',
    'nevada': 'NV',
    'new hampshire': 'NH',
    'new jersey': 'NJ',
    'new mexico': 'NM',
    'new york': 'NY',
    'north carolina': 'NC',
    'north dakota': 'ND',
    'ohio': 'OH',
    'oklahoma': 'OK',
    'oregon': 'OR',
    'pennsylvania': 'PA',
    'rhode island': 'RI',
    'south carolina': 'SC',
    'south dakota': 'SD',
    'tennessee': 'TN',
    'texas': 'TX',
    'utah': 'UT',
    'vermont': 'VT',
    'virginia': 'VA',
    'washington': 'WA',
    'west virginia': 'WV',
    'wisconsin': 'WI',
    'wyoming': 'WY',
};

export const getProvinceStateAbbreviation = (fullName: string): string => {
    const normalized = fullName.toLowerCase().trim();
    return provinceStateMapping[normalized] || fullName;
};