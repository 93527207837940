import AnnualCashFlowChart from "components/organisms/Charts/AnnualCashFlowChart";
import { useMemo } from "react";

interface CashFlowChartMeasuresReportProps {
    rowData: {
        cashFlowAnnualHidden: any;
        incrementalCost?: number;
        likeForLikeCost?: number;
        annualCarbonTaxSavings?: number;
    };
}

function CashFlowChartMeasuresReport({ rowData }: CashFlowChartMeasuresReportProps) {
    const renamedCashFlowData = useMemo(() => 
        rowData.cashFlowAnnualHidden.map((item: any, index: number) => ({
            ...item,
            cashFlowAnnual: Number(item.cashFlowAnnualHidden) || 0,
            incrementalCost: index === 0 ? Number(rowData.incrementalCost) || 0 : 0,
            utilityCostSavingsElectricity: Number(item.utilityCostSavingsElectricity) || 0,
            utilityCostSavingsNaturalGas: Number(item.utilityCostSavingsNaturalGas) || 0,
            carbonTaxSavings: Number(rowData.annualCarbonTaxSavings) || 0,
            likeForLikeCost: index === 0 ? Number(rowData.likeForLikeCost) || 0 : 0,
            cashFlowAnnualHidden: 0,
        }))
    , [rowData]);

    const tableKeys = buildCashFlowTableColumnKeys()
    
    return (
        <AnnualCashFlowChart
            query={renamedCashFlowData}
            tableColummnKeys={tableKeys}
            barKeys={buildCashFlowChartKeys()}
            height={430}
            parentComponent="CashFlowChartMeasuresReport"
        />
    )
}

const buildCashFlowTableColumnKeys = () => {
    const keys = [
        "calendarYear",
        "likeForLikeCost",
        "incrementalCost",
        "utilityCostSavingsNaturalGas",
        "utilityCostSavingsElectricity",
        "carbonTaxSavings",
        "cashFlowAnnual",
    ];
    return keys;
};

const buildCashFlowChartKeys = () => {
    const keys = [
        "likeForLikeCost",
        "incrementalCost",
        "utilityCostSavingsNaturalGas",
        "utilityCostSavingsElectricity",
        "carbonTaxSavings",
    ];
    return keys;
};

export default CashFlowChartMeasuresReport;