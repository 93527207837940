import { Header } from "components/atoms/Typography";
import FormInput from "components/molecules/FormInput";
import { useUpdateBuildingName } from "mutations/useUpdateBuildingName";
import { useEffect, useState } from "react";
import { getDisplayInfo } from "utils/formatting";

interface BuildingNameProps {
    buildingName: string;
    buildingModelUid: string;
}

function BuildingName({ buildingName, buildingModelUid }: BuildingNameProps) {
    const [focusMode, setFocusMode] = useState(false);

    const { mutate } = useUpdateBuildingName(buildingModelUid);
    const { formatFunction } = getDisplayInfo("buildingName");

    const [buildingNameInputValue, setBuildingNameInputValue] =
        useState(buildingName);

    useEffect(() => {   
        if (buildingNameInputValue !== buildingName)
            mutate(buildingNameInputValue);
    }, [buildingNameInputValue]);

    const onClick = () => {
        setFocusMode(true);
    };

    const onBlur = () => {
        setFocusMode(false);
    };

    return (
        <div>
            {focusMode ? (
                <FormInput
                    value={buildingNameInputValue}
                    setValue={(value) =>
                        setBuildingNameInputValue(value?.toString() || "")
                    }
                    attributeKey="buildingName"
                    setIsError={() => {}}
                    type="string"
                    showTitle={false}
                    style={{ fontSize: "20px" }}
                    onBlur={onBlur}
                />
            ) : (
                <button
                    className="building-name-select"
                    onClick={onClick}
                    type="button"
                >
                    <Header size="medium">
                        {formatFunction(buildingNameInputValue)}
                    </Header>
                </button>
            )}
        </div>
    );
}

export default BuildingName;