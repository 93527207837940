import mutationCreateCustomPlanFromPlan from "ApiLayer/CustomPlans/queryCreateCustomPlanFromPlan";
import { buildingModelQueryKey } from "hooks/Building/useBuildingModel";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { useMutation, useQueryClient } from "react-query";
import { showMutationAlert } from "utils/helpers";

export interface CreateCustomPlanData {
    carbonReductionPlanUid: string;
    planName: string;
    buildingModelUid: string;
}

export const useCreateCustomPlanFromPlan = () => {
    const accessToken = useAccessToken();
    const alert = useAlert();
    const queryClient = useQueryClient();

    return useMutation({
        onError: (error) => {
            showMutationAlert(error, alert);
        },
        onSuccess: (_, variables) => {
            alert.success("Custom plan created.");

            queryClient.invalidateQueries([...buildingModelQueryKey, variables.buildingModelUid]);
        },
        mutationFn: async (data: CreateCustomPlanData) => 
            mutationCreateCustomPlanFromPlan(accessToken, {
              carbonReductionPlanUid: data.carbonReductionPlanUid,
              planName: data.planName
        })
    });
}